import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  ref,
  child,
  query,
  goOffline,
  goOnline,
  onChildChanged,
  onValue,
  equalTo,
  orderByChild,
} from "firebase/database"

export const useRealtimeDeviceValues = () => {
  const { rtdb } = useContext(FirebaseContext)
  const dataRef = ref(rtdb, `vehicles`)

  const subscribeToDeviceByID = (vehicleID, onValueHandler) => {
    const q = query(child(dataRef, vehicleID))
    const unsub = onValue(q, onValueHandler)
    return unsub
  }

  const getDeviceValuesByCompanyID = (companyID, onValueHandler) => {
    const q = query(dataRef, orderByChild("account_id"), equalTo(+companyID))
    const unsub = onValue(q, onValueHandler)
    return unsub
  }

  const getDeviceValues = async (
    keys: string[],
    onValueHandler
  ): Unsubscribe => {
    const unsub = onChildChanged(dataRef, (snapshot) => {
      if (
        snapshot.exists() &&
        keys.includes(snapshot.key?.replace("vehicles/", ""))
      )
        onValueHandler(snapshot)
    })
    return unsub
  }

  return {
    subscribeToDeviceByID,
    getDeviceValuesByCompanyID,
    getDeviceValues,
    goOffline: () => goOffline(rtdb),
    goOnline: () => goOnline(rtdb),
  }
}
