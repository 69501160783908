import React from "react"
import { Router } from "@reach/router"
import { GlobalStyles, FontFamilies } from "lib/ui/theme"
import { ThemeProvider } from "styled-components"
import I18n from "app/I18n"
import PrivateRoute from "app/PrivateRoute"
import { Helmet } from "react-helmet"
import { SearchProvider } from "app/Search/context"
import { UserProvider } from "app/User/context"
import { TrackerGroupProvider } from "app/TrackerGroups/context"
import { MapProvider } from "app/GoogleMap/context"
import { SettingsProvider } from "routes/Settings/context"
import { HistoryProvider } from "routes/HistoryV2/context"
import { Zones } from "routes/Zones"
import TrackerSearch from "app/TrackerSearch"
import Start from "routes/Start"
import DashboardRoute from "routes/Dashboard"
import Service from "routes/Service"
import { History } from "routes/HistoryV2"
import Reports from "routes/Reports"
import Settings from "routes/Settings"
import Groups from "routes/Groups"
import Users from "routes/Users"
import ResetPassword from "routes/ResetPassword"
import NewPassword from "routes/NewPassword"
import FEATURE, { COMPANYFEATURES } from "data/featureFlags"
import { ZonesProvider } from "routes/Zones/context"
import { siteTheme, CustomGlobalStyles, AppGlobalStyles } from "theme"
import { ViewportContext } from "context"
import useViewport from "hooks/useViewport"
import { CompanyProvider } from "app/Company/context"
import { DeviceProvider } from "app/Device/context"
import { Alarms } from "routes/Alarms"
import { AlarmsProvider } from "routes/Alarms/context"
import { ConversationProvider } from "app/Conversations/context"
import { Conversation } from "app/Conversations"
import { Drivers } from "routes/Drivers"
import { NotificationCenterProvider } from "app/NotificationCenter/context"
import { ErrorBoundary } from "app/ErrorBoundary"
import useDevicesStore from "app/Device/store"
import useConversationStore from "app/Conversations/store"

const StartComponent = ({ user }) => {
  return (
    <>
      <Helmet title="Kort | Clevertrack" />
      <ErrorBoundary>
        <Start />
      </ErrorBoundary>
    </>
  )
}

const SettingsComponent = ({ user }) => {
  return (
    <>
      <Helmet title={I18n({ strKey: "nav_my_settings" }) + ` | Clevertrack`} />
      <ErrorBoundary>
        <Settings />
      </ErrorBoundary>
    </>
  )
}

const ZonesComponent = ({ user }) => {
  return (
    <>
      <Helmet title={I18n({ strKey: "nav_zones" }) + ` | Clevertrack`} />
      <ErrorBoundary>
        <Zones />
      </ErrorBoundary>
    </>
  )
}

const AlarmsComponent = ({ user }) => {
  return (
    <>
      <Helmet title={I18n({ strKey: "nav_alarms" }) + ` | Clevertrack`} />
      <ErrorBoundary>
        <Alarms />
      </ErrorBoundary>
    </>
  )
}

const GroupsComponent = ({ user }) => {
  return (
    <>
      <Helmet
        title={I18n({ strKey: "nav_group_settings" }) + ` | Clevertrack`}
      />
      <ErrorBoundary>
        <Groups />
      </ErrorBoundary>
    </>
  )
}

const UsersComponent = ({ user }) => {
  return (
    <>
      <Helmet title="Brugere | Clevertrack" />
      <ErrorBoundary>
        <Users myUser={user} />
      </ErrorBoundary>
    </>
  )
}

const DashboardComponent = ({ user }) => {
  return (
    <>
      <Helmet title="Dashboard | Clevertrack" />
      <ErrorBoundary>
        <DashboardRoute />
      </ErrorBoundary>
    </>
  )
}

const ServiceComponent = ({ user }) => {
  // Service doesn't utilize groups at this point. As such, we give it it's own SearchProvider,
  // so it doesn't leak in to the other search widgets.
  return (
    <SearchProvider>
      <Helmet title="Service | Clevertrack" />
      <ErrorBoundary>
        <Service user={user} />
      </ErrorBoundary>
    </SearchProvider>
  )
}

const HistoryComponent = ({ user, ...props }) => {
  return (
    <>
      <Helmet title="Historik | Clevertrack" />
      <ErrorBoundary>
        <History {...props} />
      </ErrorBoundary>
    </>
  )
}

const DriversComponent = ({ user, ...props }) => {
  return (
    <SearchProvider>
      <Helmet title="Fører ID | Clevertrack" />
      <ErrorBoundary>
        <Drivers user={user} {...props} />
      </ErrorBoundary>
    </SearchProvider>
  )
}

const ReportsComponent = ({ user }) => {
  return (
    <>
      <Helmet title="Rapporter | Clevertrack" />
      <ErrorBoundary>
        <Reports />
      </ErrorBoundary>
    </>
  )
}

function App() {
  const viewport = useViewport()
  useDevicesStore()
  useConversationStore()
  if (typeof window === "undefined") return null

  return (
    <ThemeProvider theme={siteTheme}>
      <FontFamilies />
      <GlobalStyles />
      <CustomGlobalStyles />
      <AppGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        <SettingsProvider>
          <CompanyProvider>
            <UserProvider>
              <ZonesProvider>
                <SearchProvider>
                  <TrackerGroupProvider>
                    <MapProvider>
                      <NotificationCenterProvider>
                        <AlarmsProvider>
                          <HistoryProvider>
                            <Router basepath="/app">
                              <PrivateRoute
                                path="/"
                                feature={FEATURE.START}
                                component={StartComponent}
                                dashboardSidebar={
                                  <TrackerSearch
                                    visibilityToggle={true}
                                    toggleOnInit={true}
                                  />
                                }
                                showPlatformStatus={true}
                              />
                              <PrivateRoute
                                path="/dashboard"
                                feature={COMPANYFEATURES.DASHBOARD}
                                component={DashboardComponent}
                                dashboardTitle="Dashboard"
                              />
                              <PrivateRoute
                                path="/service"
                                feature={FEATURE.SERVICE}
                                component={ServiceComponent}
                              />
                              <PrivateRoute
                                path="/history/*trackerId"
                                feature={FEATURE.HISTORY}
                                component={HistoryComponent}
                                allowOverflowScroll
                                dashboardSidebar={
                                  <TrackerSearch
                                    simple
                                    placeholder="Vælg enhed"
                                  />
                                }
                              />
                              <PrivateRoute
                                path="/reports/*reportId"
                                feature={COMPANYFEATURES.REPORTS}
                                component={ReportsComponent}
                                dashboardTitle="Rapporter"
                                allowOverflowScroll
                              />
                              <PrivateRoute
                                path="/settings"
                                allowAllAuth
                                component={SettingsComponent}
                                dashboardTitle={I18n({
                                  strKey: "nav_my_settings",
                                })}
                              />
                              <PrivateRoute
                                path="/settings/groups"
                                feature={FEATURE.GROUPS}
                                component={GroupsComponent}
                                dashboardTitle={I18n({
                                  strKey: "nav_group_settings",
                                })}
                              />
                              <PrivateRoute
                                path="/settings/users"
                                feature={FEATURE.USERS}
                                component={UsersComponent}
                                dashboardTitle="Brugere"
                              />
                              <PrivateRoute
                                path="/settings/zones"
                                feature={FEATURE.ZONES}
                                component={ZonesComponent}
                                dashboardTitle={I18n({
                                  strKey: "nav_zones",
                                })}
                              />
                              <PrivateRoute
                                path="/settings/alarms"
                                feature={FEATURE.ALARMS}
                                component={AlarmsComponent}
                                dashboardTitle={I18n({
                                  strKey: "nav_alarms",
                                })}
                              />
                              <PrivateRoute
                                path="/settings/drivers"
                                component={DriversComponent}
                                dashboardTitle="Fører ID"
                              />
                              <ResetPassword path="/reset-password" />
                              <NewPassword path="/new-password" />
                            </Router>
                          </HistoryProvider>
                          <Conversation />
                        </AlarmsProvider>
                      </NotificationCenterProvider>
                    </MapProvider>
                  </TrackerGroupProvider>
                </SearchProvider>
              </ZonesProvider>
            </UserProvider>
          </CompanyProvider>
        </SettingsProvider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

export default App

App.defaultProps = {}

App.propTypes = {}
