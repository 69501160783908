import Supercluster from "supercluster"
import min from "lodash-es/min"
import max from "lodash-es/max"
import GeoJSON, { BBox } from "geojson"
import uniq from "lodash-es/uniq"
import { getStatusKey } from "app/TrackerStatus/helper"
import { ICoordinates } from "./map.types"
import sum from "lodash-es/sum"
import { v4 } from "uuid"
import { ViewportEnum } from "hooks/useViewport"

/**
 * Creates a new set of map options based on the viewport
 * @param {object} maps
 * @param {string} viewport
 */

const createMapOptions = (viewport, settings): google.maps.MapOptions => {
  return {
    fullscreenControl: [
      ViewportEnum.Desktop,
      ViewportEnum.LargeDesktop,
    ].includes(viewport),
    streetViewControl: [
      ViewportEnum.Desktop,
      ViewportEnum.LargeDesktop,
    ].includes(viewport),
    gestureHandling: "greedy",
    minZoom: 2,
    clickableIcons: false,
    ...settings,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    isFractionalZoomEnabled: false,
    mapTypeControlOptions: {
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        // google.maps.MapTypeId.SATELLITE,
        google.maps.MapTypeId.HYBRID,
      ],
      position: [ViewportEnum.Desktop, ViewportEnum.LargeDesktop].includes(
        viewport
      )
        ? google.maps.ControlPosition.TOP_LEFT
        : google.maps.ControlPosition.LEFT_BOTTOM,
      style: [ViewportEnum.Desktop, ViewportEnum.LargeDesktop].includes(
        viewport
      )
        ? google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        : google.maps.MapTypeControlStyle.INSET,
    },
    mapTypeControl: true,
  }
}

const convertCoordinatesToGeoJson = (coords, routeID) => {
  const points = GeoJSON.parse(coords, { Point: ["pos.lat", "pos.lng"] })
  return points.features.map((x) => ({
    ...x,
    key: `${routeID}_${x.properties.timestamp}`,
  }))
}

/**
 * Get Clusters
 * Returns clusters based on the bounds and points passed in
 */

const getClusters = (points, bounds, zoom) => {
  const baseClusterRadius = 98
  const clusterSettings = {
    radius: baseClusterRadius - zoom,
    maxZoom: 16,
  }
  const cluster = new Supercluster(clusterSettings)
  cluster.load(points)
  const clusters = cluster.getClusters(bounds, Math.floor(zoom))
  return { _instance: cluster, clusters }
}

/**
 *
 * @param {Object} cluster Instance of Supercluster
 * @param {Number} id The cluster id to get info from
 */

const getStatusesInCluster = (cluster, id) => {
  try {
    return cluster
      .getLeaves(id, Infinity)
      .map((item) => getStatusKey(item.properties.status))
      .reduce((acc, curr) => {
        acc[curr] ? acc[curr]++ : (acc[curr] = 1)
        return acc
      }, {})
  } catch (error) {
    console.log(error)
  }
}

const getBoundsFromGoogleMapsBounds = (bounds: google.maps.LatLngBounds) => {
  const [ne, sw] = [
    bounds.getNorthEast().toJSON(),
    bounds.getSouthWest().toJSON(),
  ]
  return getBounds([
    { geometry: { coordinates: [ne.lng, ne.lat] } },
    { geometry: { coordinates: [sw.lng, sw.lat] } },
  ])
}

/**
 * Gets bounds creates new map boundaries based on inputtet geoJSON
 */

const getBounds = (points) => {
  const lng = points.map((point) => point.geometry.coordinates[0])
  const lat = points.map((point) => point.geometry.coordinates[1])

  if (uniq(lng).length === 1 && uniq(lat).length === 1) {
    const singleBounds = getSinglePoint(points)
    return {
      singlebound: singleBounds,
    }
  }

  const bounds = {
    multibounds: {
      nw: {
        lat: max(lat),
        lng: min(lng),
      },
      se: {
        lat: min(lat),
        lng: max(lng),
      },
      ne: {
        lat: max(lat),
        lng: max(lng),
      },
      sw: {
        lat: min(lat),
        lng: min(lng),
      },
    },
  }
  return bounds
}

/**
 * Generates a google.maps.LatLngBoundsLiteral from a @turf/bbox
 */

const getLatLngLiteralFromTurfBbox = ([
  minX,
  minY,
  maxX,
  maxY,
]: BBox): google.maps.LatLngBoundsLiteral => {
  const bounds: google.maps.LatLngBoundsLiteral = {
    east: maxX,
    west: minX,
    north: maxY,
    south: minY,
  }
  return bounds
}

const getCenterFromBounds = (points: ICoordinates[]): ICoordinates => {
  const avgLat = sum(points.map((coord) => coord.lat)) / points.length
  const avgLng = sum(points.map((coord) => coord.lng)) / points.length

  return {
    lat: avgLat,
    lng: avgLng,
  }
}

const getSinglePoint = (points) => {
  const lng = points[0].geometry.coordinates[0]
  const lat = points[0].geometry.coordinates[1]
  return { lat, lng }
}

interface IModFitBoundFactors {
  zoomFactor?: number
  offsetFactor?: [number, number]
}

interface IZoomCenterConfig {
  zoom: number
  center: ICoordinates
}

const defaultFactors: IModFitBoundFactors = {
  zoomFactor: 100,
  offsetFactor: [0, 0],
}

// input should originate from google-map-react/utils/fitBounds
// Modifies output for better UX
const uxModFitBounds = (
  input: IZoomCenterConfig,
  factors: IModFitBoundFactors = defaultFactors
): IZoomCenterConfig => {
  // Factors for zoom, latitude and longitude center
  // zoomFactor = 100 means no change
  // offsetFactor = [0, 0] means no change lat and lng
  const {
    zoomFactor,
    offsetFactor: [latFactor, lngFactor],
  } = factors
  const { center, zoom } = input
  const zoom_ = zoom * (zoomFactor / 100)

  const modifyCoordinate = (i) => i / 10000 + 1
  const center_ = {
    ...center,
    lat: center.lat,
    lng: center.lng,
  }

  return {
    center: center_,
    zoom: zoom_,
  }
}

const googleMapsAddressHelper = (results: any) => {
  if (results) {
    const result =
      results.find((res: any) => res.types.includes("route")) ??
      results.find((res: any) => res.types.includes("street_address"))

    const {
      short_name,
      long_name,
    } = result.address_components.find((comp: any) =>
      comp.types.includes("route")
    )
    const city = result.address_components.find(
      (comp: any) =>
        comp.types.includes("postal_town") ||
        comp.types.includes("locality") ||
        comp.types.includes("sublocality") ||
        comp.types.includes("administrative_area_level_2")
    )
    const postal_code = result.address_components.find((comp: any) =>
      comp.types.includes("postal_code")
    )
    const country = result.address_components.find((comp: any) =>
      comp.types.includes("country")
    )

    const main_name =
      short_name === long_name ? long_name : `${short_name}, ${long_name}`

    return [
      main_name,
      `${postal_code.long_name} ${city.long_name}`,
      country ? country.long_name : null,
    ]
      .filter(Boolean)
      .join(", ")
  }
  return ""
}

export {
  createMapOptions,
  convertCoordinatesToGeoJson,
  getClusters,
  getStatusesInCluster,
  getBoundsFromGoogleMapsBounds,
  getBounds,
  getLatLngLiteralFromTurfBbox,
  getCenterFromBounds,
  getSinglePoint,
  uxModFitBounds,
  googleMapsAddressHelper,
}
