import { ActionMap, createActions } from "lib/reducer.types"
import { IDevice, IDeviceGroup } from "./types"

/* Action types */
export enum DeviceTypes {
  SetSyncAddress = "Device/SetSyncAddress",
  SetDevices = "Device/SetDevices",
  SetDeviceGroups = "Device/SetDeviceGroups",
  SetToggledDevices = "Device/SetToggledDevices",
  SetToggledDeviceGroups = "Device/SetToggledDeviceGroups",
  SetAnimating = "Device/SetAnimating",
  SetLoading = "Device/SetLoading",
  ToggleShowCurrentRoute = "Device/ShowCurrentRoute",
  ToggleDeviceSubscription = "Device/ToggleDeviceSubscription",
  UpdateDeviceByID = "Device/UpdateDeviceByID",
  UpdateManyDevices = "Device/UpdateManyDevices",
  ToggleDeviceGroup = "Device/ToggleDeviceGroup",
  ToggleDeviceGroupVisbility = "Device/ToggleDeviceGroupVisbility",
  ToggleDeviceInGroup = "Device/ToggleDeviceInGroup",
  ToggleGroupVisibilitySetup = "Device/ToggleGroupVisibilitySetup",
  UntoggleAllDevices = "Device/UntoggleAllDevices",
  AddDeviceAlarm = "Device/AddDeviceAlarm",
  Reset = "Device/Reset",
}

/* Action creators */
type Actions = {
  [DeviceTypes.SetSyncAddress]: { sync: boolean }
  [DeviceTypes.SetDevices]: { devices: IDevice[] }
  [DeviceTypes.SetDeviceGroups]: { groups: IDeviceGroup[] }
  [DeviceTypes.SetToggledDevices]: { devices: IDevice[] }
  [DeviceTypes.SetToggledDeviceGroups]: { groups: IDeviceGroup[] }
  [DeviceTypes.SetAnimating]: boolean
  [DeviceTypes.SetLoading]: boolean
  [DeviceTypes.ToggleShowCurrentRoute]: boolean
  [DeviceTypes.ToggleGroupVisibilitySetup]: null
  [DeviceTypes.ToggleDeviceGroup]: { groupID: number }
  [DeviceTypes.ToggleDeviceGroupVisbility]: { groupID: number }
  [DeviceTypes.ToggleDeviceInGroup]: {
    groupID: number
    deviceID: number
    untoggleRest?: boolean
    toggleDevice?: boolean
  }
  [DeviceTypes.ToggleDeviceSubscription]: { deviceID: number }
  [DeviceTypes.UpdateDeviceByID]: { id: number; device: Partial<IDevice> }
  [DeviceTypes.UpdateManyDevices]: { devices: IDevice[] }
  [DeviceTypes.UntoggleAllDevices]: null
  [DeviceTypes.AddDeviceAlarm]: { deviceID: number; alarmID: string }
  [DeviceTypes.Reset]: null
}

export type DeviceActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const DeviceActions = createActions<Actions>()
