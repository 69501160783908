import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  addDoc,
  doc,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  QuerySnapshot,
  query,
  where,
  getDoc,
} from "firebase/firestore"
import { CompanyContext } from "app/Company/context"
import { Alarm, AlertNotification } from "routes/Alarms/alarm.types"

export const useFirestoreAlarms = () => {
  const { db } = useContext(FirebaseContext)
  const {
    state: { company },
  } = useContext(CompanyContext)

  const createNewFirebaseAlarm = async (payload: Alarm) => {
    try {
      if (company && db) {
        const alarmCollection = collection(db, `companies/${company.id}/alarms`)
        const newAlarmDoc = await addDoc(alarmCollection, payload)
        await updateDoc(newAlarmDoc, {
          id: newAlarmDoc.id,
        })
        const alarm = await getDoc(newAlarmDoc)
        if (alarm.exists()) return alarm.data() as Alarm
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const getFirebaseAlarms = async () => {
    try {
      if (company && db) {
        const alarmCollection = query(
          collection(db, `companies/${company.id}/alarms`)
        )
        const alarmDocs = await getDocs(alarmCollection)
        return alarmDocs.docs.map((document) => document.data() as Alarm)
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const updateFirebaseAlarm = async (payload: Alarm) => {
    try {
      if (company && db && payload.id) {
        const existingAlarm = doc(
          db,
          `companies/${company.id}/alarms/${payload.id}`
        )

        await updateDoc(existingAlarm, {
          ...payload,
        })

        const alarm = await getDoc(existingAlarm)
        if (alarm.exists()) return alarm.data() as Alarm
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const deleteFirebaseAlarm = async (alarmID: string) => {
    try {
      if (company && db && alarmID) {
        const existingAlarm = doc(
          db,
          `companies/${company.id}/alarms/${alarmID}`
        )

        await deleteDoc(existingAlarm)
        return "OK"
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const createNewFirebaseAlarmNotification = async (
    payload: AlertNotification
  ) => {
    try {
      if (company && db) {
        const alarmNotificationCollection = collection(
          db,
          `companies/${company.id}/alarmnotifications`
        )
        const newAlarmNotificationDoc = await addDoc(
          alarmNotificationCollection,
          payload
        )
        await updateDoc(newAlarmNotificationDoc, {
          id: newAlarmNotificationDoc.id,
        })
        return newAlarmNotificationDoc.id
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const getFirebaseAlarmNotifications = async (ids: string[]) => {
    try {
      if (company && db && ids.length > 0) {
        const alertNotificationCollection = query(
          collection(db, `companies/${company.id}/alarmnotifications`),
          where("id", "in", ids)
        )
        const alertNotificationDocs = await getDocs(alertNotificationCollection)
        return alertNotificationDocs.docs.map(
          (document) => document.data() as AlertNotification
        )
      }
      return []
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const updateFirebaseAlarmNotification = async (
    payload: AlertNotification
  ) => {
    try {
      if (company && db && payload.id) {
        const existingAlarmNotification = doc(
          db,
          `companies/${company.id}/alarmnotifications/${payload.id}`
        )

        await updateDoc(existingAlarmNotification, payload)
        return "OK"
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  const deleteFirebaseAlarmNotification = async (notificationID: string) => {
    try {
      if (company && db && notificationID) {
        const existingAlarmNotification = doc(
          db,
          `companies/${company.id}/alarmnotifications/${notificationID}`
        )

        await deleteDoc(existingAlarmNotification)
        return "OK"
      }
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  return {
    createNewFirebaseAlarm,
    createNewFirebaseAlarmNotification,
    getFirebaseAlarms,
    getFirebaseAlarmNotifications,
    updateFirebaseAlarm,
    updateFirebaseAlarmNotification,
    deleteFirebaseAlarm,
    deleteFirebaseAlarmNotification,
  }
}
