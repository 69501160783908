import React, { useContext, useEffect, useState, useRef, useMemo } from "react"
import format from "date-fns/format"
import styled from "styled-components"
import cogoToast from "@clevertrackdk/cogo-toast"
import isEqual from "lodash-es/isEqual"
import tw from "twin.macro"
import { IconFlip } from "app/IconFlip"
import { CreateListItem } from "app/ResultListGroup/CreateListItem"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Responsive,
  Textarea,
  Loading,
} from "@clevertrack/shared"
import { Select } from "lib/Select"
import { useFormData } from "hooks/useFormData"
import { Group as ListGroup } from "lib/List/Group"
import {
  Alarm,
  AlarmHintEnum,
  AlarmSchedule,
  AlarmSetupPanels,
  AlertCriteriumTypeEnum,
  AlertNotification,
  IAlarmNotificationRecipient,
} from "../alarm.types"
import { useAlarms } from "../hooks"
import { alarmTemplates } from "../templates"
import Input from "lib/Input"
import Accordion from "lib/Accordion"
import { DeviceStatusCriteriaElement } from "./criteria/DeviceStatus"
import { AlarmsContext } from "../context"
import { AlarmActions, AlarmTypes } from "../actions"
import Icon, { IconSizeEnum } from "lib/Icon"
import { usePromise } from "hooks/usePromise"
import { WeekDays } from "lib/global.types"
import { AlarmConfigurationComponent } from "./AlarmConfigurationComponent"
import { DeviceZoneCriteriaElement } from "./criteria/DeviceZone"
import { DeviceUpdateCriteriaElement } from "./criteria/DeviceUpdate"
import { DeviceHealthCriteriaElement } from "./criteria/DeviceHealth"
import { useFirestoreAlarms } from "services/firestore/alarms"
import { Hint, HintIcon } from "app/Hint"
import Checkbox from "lib/Checkbox"
import { DeviceSpeedCriteriaElement } from "./criteria/DeviceSpeed"
import { alarmNotificationTemplates } from "./Notifications/templates"
import useDevicesStore from "app/Device/store"

const TemplateOption = ({ innerProps, innerRef, data }) => {
  return (
    <div
      tw="px-4 py-4 hover:bg-brand-gray-lighter cursor-pointer"
      ref={innerRef}
      {...innerProps}
    >
      <span tw="block text-2xl">{data.value.title}</span>
      <span tw="block text-lg opacity-60">{data.value.description}</span>
    </div>
  )
}

const templateGroups = [
  {
    label: "Standardalarmer",
    options: alarmTemplates.map((template) => ({
      value: template,
      label: template.title,
    })),
  },
]

export const StyledEditIcon = styled(Icon)`
  cursor: pointer;
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.5rem;
`

export const AlarmForm: React.FC<{
  onCreateNotification: (
    notification: AlertNotification,
    omitToast: boolean
  ) => Promise<string>
  onCancelEdit: () => void
  onEditPart: () => void
}> = ({ onCreateNotification, onCancelEdit, onEditPart, ...props }) => {
  const {
    editAlarm,
    onCancelEditAlarm,
    onEditCriterium,
    onEditAlarm,
    onSaveAlarm,
    onSaveAlarmDraft,
    onGetAlarmNotifications,
    onDeleteAlarmNotification,
  } = useAlarms()
  const { createNewFirebaseAlarmNotification } = useFirestoreAlarms()
  const { devices } = useDevicesStore((state) => ({
    devices: state.devices,
  }))

  const {
    state: {
      panel,
      alertNotifications,
      currentCriterium,
      currentSchedule,
      alarms,
    },
    dispatch,
  } = useContext(AlarmsContext)
  const [selectedTemplate, setSelectedTemplate] = useState<null | {
    id: string
    title: string
  }>(null)
  const [showAvailableTemplates, setShowAvailableTemplates] = useState(
    editAlarm?.title === "Ny alarm" && !editAlarm.updated
  )
  const [showDescription, setShowDescription] = useState(
    editAlarm?.description || false
  )
  const { loadingText, loading, setPromise } = usePromise()
  const [confirmDeleteID, setConfirmDeleteID] = useState<null | string>(null)
  const lastUpdated = useRef(editAlarm?.updated)

  const onConfirmDelete = async () => {
    // await deleteAlarm(id)
    const res = await onDeleteAlarmNotification(confirmDeleteID)
    if (res === "OK") {
      setConfirmDeleteID(null)
    }
  }

  const onConfirmDeleteSchedule = async () => {
    if (editAlarm && editAlarm.schedule) {
      const newAlarmUpdate = {
        ...editAlarm,
        schedule: editAlarm.schedule.filter((x) => x.id !== confirmDeleteID),
      }
      await onSaveAlarmDraft(newAlarmUpdate)
    }
  }

  const onDeleteHandler = (id?: string) => {
    if (id) {
      setConfirmDeleteID(id)
    } else setConfirmDeleteID(null)
  }

  const [
    dataModel,
    updateDataModelState,
    resetDataModel,
    setDataModel,
  ] = useFormData<Alarm>(editAlarm)

  const updateDataModel = async (key: any, value: any) => {
    await onSaveAlarmDraft({
      ...editAlarm,
      [key]: value,
    })
    updateDataModelState(key, value)
  }

  const onAddHandler = (p: AlarmSetupPanels) => {
    if (panel === p) {
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
          panel: null,
        })
      )
    } else {
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
          panel: p,
        })
      )
    }
  }

  const onEditNotificationHandler = (alertNotification: AlertNotification) => {
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentAlertNotification, {
        alertNotification,
      })
    )
  }

  const onCancel = () => {
    if (onCancelEdit) onCancelEdit()
    onCancelEditAlarm()
  }

  const onSaveSuccessHandler = () => {
    cogoToast.success("Alarm gemt")
    onCancelEditAlarm()
    dispatch(AlarmActions(AlarmTypes.Reset, null))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    await setPromise({
      promise: onSaveAlarm(dataModel),
      onSuccess: () => onSaveSuccessHandler(),
      loadingText: "Gemmer",
    })
  }

  const onSelectTemplate = async (opt) => {
    const { id, ...rest } = opt.value as Alarm
    setSelectedTemplate({ id, title: `${rest.title}` })
    setShowAvailableTemplates(false)
    setShowDescription(true)
    const newDataModel = { ...editAlarm, ...rest }
    setDataModel(newDataModel)

    if (
      rest.criteria &&
      rest.criteria.length > 0 &&
      rest.criteria[0].type === AlertCriteriumTypeEnum.DeviceZone
    ) {
      onEditCriterium(rest.criteria[0])
    }

    /* await onSaveAlarmDraft({
      ...newDataModel,
    }) */

    const alertNotification = alarmNotificationTemplates.find(
      (template) => template.refID === id
    )

    if (alertNotification) {
      const { refID, id: tempID, ...newNotification } = alertNotification
      const notificationID = await createNewFirebaseAlarmNotification(
        newNotification as AlertNotification
      )
      if (
        editAlarm?.alertNotificationIDs &&
        editAlarm.alertNotificationIDs.length > 0
      ) {
        // A new template was selected. Remove previous notifications.
        await Promise.all([
          ...editAlarm.alertNotificationIDs.map((x) =>
            onDeleteAlarmNotification(x)
          ),
        ])
      }
      if (notificationID) {
        await onSaveAlarmDraft({
          ...newDataModel,
          alertNotificationIDs: [notificationID],
        })
      }
    } else {
      await onSaveAlarmDraft({
        ...newDataModel,
        alertNotificationIDs: dataModel?.alertNotificationIDs,
      })
    }
  }

  const onEditScheduleHandler = (schedule: AlarmSchedule) => {
    if (schedule && schedule?.id === currentSchedule?.id) {
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentSchedule, {
          schedule: null,
        })
      )
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
          panel: null,
        })
      )
    } else {
      onAddHandler(AlarmSetupPanels.AlertScheduleEditor)
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentSchedule, {
          schedule,
        })
      )
    }
  }

  const renderScheduleHint = (schedule: AlarmSchedule) => {
    const titleString: string[] = []
    const bodyString: string[] = []
    if (schedule.intervals && schedule?.intervals.length > 0) {
      bodyString.push(
        format(schedule.intervals[0].interval[0], "dd.MM.yyyy - ")
      )
      bodyString.push(format(schedule.intervals[0].interval[1], "dd.MM.yyyy"))
      if (schedule.intervals.length > 1) {
        titleString.push("Faste perioder")
        bodyString.push(
          ` og ${schedule.intervals.length - 1} ${
            schedule.intervals.length - 1 > 1
              ? "øvrige perioder"
              : "øvrig periode"
          }`
        )
      } else {
        titleString.push("Fast periode")
      }
    }

    if (schedule.weekdays) {
      if (schedule.intervals && schedule.intervals.length > 0) {
        titleString.push(` på udvalgte ugedage`)
      } else {
        // No intervals selected
        Object.keys(schedule.weekdays).length === 7
          ? titleString.push("Alle ugens dage")
          : titleString.push("Udvalgte ugedage")
      }

      let sunday
      const weekdaysString = []
      for (const weekday of Object.entries(schedule.weekdays)) {
        const [key, value] = weekday
        if (+key === 0) {
          sunday = `${WeekDays[key].long}`
        } else {
          weekdaysString.push(`${WeekDays[key].long}`)
        }
      }

      if (sunday) {
        bodyString.push(sunday)
      }

      schedule.intervals && schedule.intervals.length > 0
        ? bodyString.push(`(${weekdaysString.join(", ")})`)
        : bodyString.push(`${weekdaysString.join(", ")}`)
    }

    return [titleString.join(", "), bodyString.join(" ")]
  }

  const renderAssignedDeviceHint = () => {
    if (dataModel && dataModel?.deviceIDs && devices) {
      let moreString = ""
      const deviceNamesString = []
      const displayDevices = dataModel.deviceIDs.slice(0, 3)
      for (const deviceID of displayDevices) {
        const index = displayDevices.indexOf(deviceID)
        const deviceName = devices.find(
          (device) => device.id.toString() === deviceID
        )?.name
        if (
          dataModel.deviceIDs.length > 1 &&
          displayDevices.length === dataModel.deviceIDs.length &&
          index === displayDevices.length - 1
        ) {
          // Display three devices
          deviceNamesString.push(` og ${deviceName}`)
        } else if (index > 0) deviceNamesString.push(`, ${deviceName}`)
        else deviceNamesString.push(deviceName)
      }
      // Handle cases where more than 3 devices are selected
      if (dataModel.deviceIDs.length - deviceNamesString.length > 0) {
        moreString = `og ${
          dataModel.deviceIDs.length - deviceNamesString.length
        } ${
          dataModel?.deviceIDs.length - deviceNamesString.length === 1
            ? "mere"
            : "flere"
        }`
      }
      return [deviceNamesString.join(""), moreString].join(" ")
    }
  }

  const renderAlarmNotificationHint = (notification: AlertNotification) => {
    let moreString = ""
    const notificationNamesString: string[] = []
    const displayRecipients = notification.recipients.slice(0, 3)

    const renderName = (recipient: IAlarmNotificationRecipient) => {
      return [
        recipient.first_name,
        recipient.last_name ? `${recipient.last_name?.substring(0, 1)}.` : null,
      ]
        .filter(Boolean)
        .join(" ")
    }

    for (const recipient of displayRecipients) {
      const index = displayRecipients.indexOf(recipient)
      if (
        displayRecipients.length > 1 &&
        displayRecipients.length === notification.recipients.length &&
        index === displayRecipients.length - 1
      ) {
        // Display three devices
        notificationNamesString.push(` og ${renderName(recipient)}`)
      } else if (index > 0)
        notificationNamesString.push(`, ${renderName(recipient)}`)
      else notificationNamesString.push(renderName(recipient))
    }
    // Handle cases where more than 3 devices are selected
    if (notification.recipients.length - notificationNamesString.length > 0) {
      moreString = `og ${
        notification.recipients.length - notificationNamesString.length
      } ${
        notification.recipients.length - notificationNamesString.length === 1
          ? "mere"
          : "flere"
      }`
    }
    return [notificationNamesString.join(""), moreString].join(" ")
  }

  const alarmHasChanges = useMemo(() => {
    const existingAlarm = alarms.find((x) => x.id === dataModel?.id)
    const {
      updated: editAlarmUpdated,
      draft: dataModelDraft,
      ...editAlarmContent
    } = dataModel
    if (existingAlarm && existingAlarm.updated) {
      let existingAlarmData
      const { draft, updated, ...restWithoutUpdated } = existingAlarm
      existingAlarmData = restWithoutUpdated
      /* if (existingAlarm.hasOwnProperty("draft")) {
        const { draft, updated, ...restWithoutDraft } = existingAlarm
        existingAlarmData = restWithoutDraft
      } */
      return (
        existingAlarmData.id && !isEqual(existingAlarmData, editAlarmContent)
      )
    } else if (existingAlarm) {
      const { draft, updated, ...restWithoutDraft } = existingAlarm
      return restWithoutDraft.id && !isEqual(restWithoutDraft, editAlarmContent)
    } else return false
  }, [editAlarm, dataModel, alarms])

  useEffect(() => {
    if (editAlarm) {
      setDataModel(editAlarm)
      onGetAlarmNotifications()
    }
  }, [editAlarm])

  useEffect(() => {
    onGetAlarmNotifications()
  }, [dataModel?.alertNotificationIDs])

  /* useEffect(() => {
    if (alarmHasChanges && ) {
      onEditAlarm(dataModel)
    }
  }, [dataModel]) */

  useEffect(() => {
    return () => {
      lastUpdated.current = 0
    }
  }, [])

  useEffect(() => {
    if (alarmHasChanges && selectedTemplate === null) {
      setShowAvailableTemplates(false)
    }
  }, [alarmHasChanges])

  const onConfirmDeleteCriteria = async (criteriaID) => {
    if (dataModel && dataModel.criteria) {
      const newCriteria = dataModel?.criteria.filter(
        (criterium) => criterium.id !== criteriaID
      )
      setConfirmDeleteCriteriaID(null)
      setDataModel({
        ...dataModel,
        criteria: newCriteria,
      })
    }
  }

  const [confirmDeleteCriteriaID, setConfirmDeleteCriteriaID] = useState(null)
  const renderCriteriaDeletion = (criteria) => {
    return criteria.id !== confirmDeleteCriteriaID ? (
      <span
        tw="flex items-center text-brand-red-500 ml-4 cursor-pointer hover:opacity-60 transition"
        onClick={() => setConfirmDeleteCriteriaID(criteria.id)}
      >
        <span tw="mr-2 text-lg">Slet</span>
        <Icon size={IconSizeEnum.SM} icon="close" />
      </span>
    ) : (
      <>
        <span
          tw="flex items-center ml-4 cursor-pointer hover:opacity-60 transition"
          onClick={() => setConfirmDeleteCriteriaID(null)}
        >
          <span tw="mr-2 text-lg">Annullér</span>
        </span>
        <span
          tw="flex items-center text-brand-red-500 ml-4 cursor-pointer hover:opacity-60 transition"
          onClick={() => onConfirmDeleteCriteria(criteria.id)}
        >
          <span tw="mr-2 text-lg">Bekræft</span>
        </span>
      </>
    )
  }

  const saveAlarmDisabled = useMemo(() => {
    return (
      !alarmHasChanges ||
      (dataModel &&
        dataModel?.criteria?.find(
          (x) => x.type === AlertCriteriumTypeEnum.DeviceZone && !x.zoneID
        )) ||
      (dataModel && !dataModel?.deviceIDs) ||
      !alertNotifications.some(
        (notification) => notification.recipients.length > 0
      )
    )
  }, [alarmHasChanges, dataModel, alertNotifications])

  return (
    <>
      <header tw="flex items-center justify-between hidden lg:block">
        <h3>Alarm opsætning</h3>
      </header>
      <Form onSubmit={onSubmit}>
        <div tw="relative">
          {loading && <Loading loadingText={loadingText} />}
          {showAvailableTemplates ? (
            <FormField label="Skabelon" tw="mb-8">
              <Select
                components={{ Option: TemplateOption }}
                placeholder="Vælg en alarmskabelon"
                options={templateGroups}
                onChange={onSelectTemplate}
                blurInputOnSelect
                tw="text-3xl z-50"
              />
            </FormField>
          ) : (
            <span tw="flex items-center pb-8 text-xl">
              {selectedTemplate && (
                <span tw="mr-4">Valgt skabelon: {selectedTemplate.title}</span>
              )}
              <span
                tw="flex items-center text-brand-500 cursor-pointer hover:opacity-60 transition"
                onClick={() => setShowAvailableTemplates(true)}
              >
                <span tw="mr-2 border-dotted border-0 border-b ">
                  {selectedTemplate
                    ? `Vælg en anden skabelon`
                    : `Vælg en skabelon`}
                </span>
                <Icon size={IconSizeEnum.SM} icon="chevron-right" />
              </span>
            </span>
          )}

          {!showAvailableTemplates ? (
            <FormField label="Titel" validationKey="title">
              <Input
                type="text"
                placeholder="Indtast en genkendelig titel"
                value={editAlarm?.title}
                onChange={(e) => updateDataModel("title", e.target.value)}
              />
            </FormField>
          ) : (
            <span tw="flex items-center text-xl">
              <span
                tw="flex items-center text-brand-500 cursor-pointer hover:opacity-60 transition"
                onClick={() => setShowAvailableTemplates(false)}
              >
                <span tw="mr-2 border-dotted border-0 border-b ">
                  Opsæt en brugerdefineret alarm
                </span>
                <Icon size={IconSizeEnum.SM} icon="chevron-right" />
              </span>
            </span>
          )}
          {!showDescription && !showAvailableTemplates && (
            <span
              tw="flex text-xl mt-8 items-center text-brand-500 cursor-pointer hover:opacity-60 transition"
              onClick={() => setShowDescription(true)}
            >
              <span tw="mr-2 border-dotted border-0 border-b ">
                Tilføj beskrivelse
              </span>
              <Icon size={IconSizeEnum.SM} icon="plus" />
            </span>
          )}

          <Accordion toggled={showDescription && !showAvailableTemplates}>
            <FormField
              label="Beskrivelse"
              validationKey="description"
              tw="mt-8"
            >
              <Textarea
                value={editAlarm?.description}
                placeholder="Indtast en beskrivelse af alarmen"
                onChange={(e) => updateDataModel("description", e.target.value)}
                rows="1"
              />
            </FormField>
          </Accordion>
          {!showAvailableTemplates && (
            <>
              <div tw="mt-8">
                <Checkbox
                  appearance="toggle"
                  checked={!dataModel?.disabled}
                  onChange={(checked) => updateDataModel("disabled", !checked)}
                  tw="flex items-center"
                >
                  <span tw="text-xl">
                    {dataModel?.disabled
                      ? `Alarmen er deaktiveret`
                      : `Alarmen er aktiveret`}
                  </span>
                </Checkbox>
              </div>
              <h4 tw="mb-2">
                Kriterier
                <HintIcon type={AlarmHintEnum.NoSelectedCriteria} />
              </h4>
              <Hint
                type={AlarmHintEnum.NoSelectedCriteria}
                tw="text-xl mt-4 mb-4"
              />
              <ListGroup tw="space-y-0" as="div">
                {dataModel?.criteria &&
                  dataModel?.criteria
                    .sort((a, b) =>
                      a.created > b.created || a.type > b.type ? 1 : -1
                    )
                    .map((criterium, i) => {
                      switch (criterium.type) {
                        case AlertCriteriumTypeEnum.DeviceStatus:
                          return (
                            <DeviceStatusCriteriaElement
                              key={`${criterium.type}_${i}`}
                              criterium={criterium}
                              editing={currentCriterium?.id === criterium.id}
                              onEdit={() => onEditCriterium(criterium)}
                            >
                              {renderCriteriaDeletion(criterium)}
                            </DeviceStatusCriteriaElement>
                          )
                        case AlertCriteriumTypeEnum.DeviceZone:
                          return (
                            <DeviceZoneCriteriaElement
                              key={`${criterium.type}_${i}`}
                              criterium={criterium}
                              editing={currentCriterium?.id === criterium.id}
                              onEdit={() => onEditCriterium(criterium)}
                            >
                              {renderCriteriaDeletion(criterium)}
                            </DeviceZoneCriteriaElement>
                          )
                        case AlertCriteriumTypeEnum.DeviceHealth:
                          return (
                            <DeviceHealthCriteriaElement
                              key={`${criterium.type}_${i}`}
                              criterium={criterium}
                              editing={currentCriterium?.id === criterium.id}
                              onEdit={() => onEditCriterium(criterium)}
                            >
                              {renderCriteriaDeletion(criterium)}
                            </DeviceHealthCriteriaElement>
                          )
                        case AlertCriteriumTypeEnum.DeviceUpdate:
                          return (
                            <DeviceUpdateCriteriaElement
                              key={`${criterium.type}_${i}`}
                              criterium={criterium}
                              editing={currentCriterium?.id === criterium.id}
                              onEdit={() => onEditCriterium(criterium)}
                            >
                              {renderCriteriaDeletion(criterium)}
                            </DeviceUpdateCriteriaElement>
                          )
                        case AlertCriteriumTypeEnum.DeviceSpeed:
                          return (
                            <DeviceSpeedCriteriaElement
                              key={`${criterium.type}_${i}`}
                              criterium={criterium}
                              editing={currentCriterium?.id === criterium.id}
                              onEdit={() => onEditCriterium(criterium)}
                            >
                              {renderCriteriaDeletion(criterium)}
                            </DeviceSpeedCriteriaElement>
                          )
                        default:
                          return null
                      }
                    })}
                <CreateListItem
                  tw="py-4 px-6"
                  onClick={() =>
                    onAddHandler(AlarmSetupPanels.CriteriaTypeSelector)
                  }
                >
                  <span>Tilføj nyt kriterie +</span>
                </CreateListItem>
              </ListGroup>
              <h4 tw="mb-2">
                Enheder
                <HintIcon type={AlarmHintEnum.NoSelectedDevices} />
              </h4>
              <Hint type={AlarmHintEnum.NoSelectedDevices} tw="my-4" />
              <ListGroup as="div">
                {!dataModel.deviceIDs || dataModel?.deviceIDs.length === 0 ? (
                  <CreateListItem
                    tw="py-4 px-6"
                    onClick={() =>
                      onAddHandler(AlarmSetupPanels.AlertDeviceEditor)
                    }
                  >
                    <span tw="flex">
                      <span tw="mr-2">
                        Ingen enheder valgt. Tildel enheder til alarmen
                      </span>
                      <IconFlip
                        size="sm"
                        iconOn="chevron-left"
                        iconOff="chevron-right"
                        toggled={panel === AlarmSetupPanels.AlertDeviceEditor}
                      />
                    </span>
                  </CreateListItem>
                ) : (
                  <AlarmConfigurationComponent tw="border-b">
                    <span tw="flex text-2xl">
                      <span tw="text-xl font-bold">
                        {dataModel?.deviceIDs.length === 1
                          ? "Én enhed tildelt alarmen"
                          : `${dataModel?.deviceIDs.length} enheder tildelt alarmen`}
                      </span>
                      <span
                        tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
                        onClick={() =>
                          onAddHandler(AlarmSetupPanels.AlertDeviceEditor)
                        }
                      >
                        {panel === AlarmSetupPanels.AlertDeviceEditor ? (
                          <>
                            <span tw="text-lg text-brand-red-300">
                              Annullér
                            </span>
                          </>
                        ) : (
                          <>
                            <span tw="text-lg">Redigér</span>
                            <StyledEditIcon icon="edit" size="sm" />
                          </>
                        )}
                      </span>
                    </span>
                    <span tw="block text-lg opacity-60">
                      {renderAssignedDeviceHint()}
                    </span>
                  </AlarmConfigurationComponent>
                )}
              </ListGroup>

              <h4 tw="mb-2">
                Underretning ved alarm
                <HintIcon type={AlarmHintEnum.NoSelectedNotifications} />
              </h4>

              <Hint
                type={AlarmHintEnum.NoSelectedNotifications}
                tw="text-xl mt-4 mb-4"
              />
              <ListGroup as="div" tw="space-y-0">
                {alertNotifications
                  .filter(
                    (notification) =>
                      notification.id &&
                      dataModel?.alertNotificationIDs.includes(notification.id)
                  )
                  .map((notification) => {
                    return (
                      <AlarmConfigurationComponent
                        key={notification.id}
                        css={
                          notification.recipients.length !== 0
                            ? [tw`hover:cursor-default`]
                            : []
                        }
                        onClick={() => {
                          if (notification.recipients.length === 0) {
                            onAddHandler(
                              AlarmSetupPanels.AlertNotificationEditor
                            )
                            onEditNotificationHandler(notification)
                          }
                        }}
                        // tw="border-b-0"
                      >
                        <span tw="flex text-2xl">
                          <span tw="text-lg md:(text-xl) font-bold">
                            {notification.recipients.length === 1
                              ? "Én modtager af underretningen"
                              : `${
                                  notification.recipients.length === 0
                                    ? `Ingen`
                                    : `${notification.recipients.length}`
                                } modtagere af underretningen`}
                          </span>
                          {notification.recipients.length !== 0 ? (
                            notification.id !== confirmDeleteID ? (
                              <span
                                tw="flex items-center text-brand-red-300 ml-4 hover:cursor-pointer hover:opacity-60 transition"
                                onClick={() => onDeleteHandler(notification.id)}
                              >
                                <span tw="mr-2 text-lg">Slet</span>
                                <Icon size={IconSizeEnum.SM} icon="close" />
                              </span>
                            ) : (
                              <>
                                <span
                                  tw="flex items-center ml-4 hover:(opacity-60 cursor-pointer) transition"
                                  onClick={() => onDeleteHandler()}
                                >
                                  <span tw="mr-2 text-lg">Annullér</span>
                                </span>
                                <span
                                  tw="flex items-center text-brand-red-300 ml-4 hover:(cursor-pointer opacity-60) transition"
                                  onClick={() => onConfirmDelete()}
                                >
                                  <span tw="mr-2 text-lg">Bekræft</span>
                                </span>
                              </>
                            )
                          ) : null}

                          {confirmDeleteID !== notification.id && (
                            <span
                              tw="flex ml-auto items-center justify-center transition-all hover:(cursor-pointer text-brand-500)"
                              onClick={() => {
                                if (notification.recipients.length !== 0) {
                                  onAddHandler(
                                    AlarmSetupPanels.AlertNotificationEditor
                                  )
                                  onEditNotificationHandler(notification)
                                }
                              }}
                            >
                              <span tw="text-lg">
                                {notification.recipients.length !== 0
                                  ? `Redigér`
                                  : `Vælg`}
                              </span>
                              <StyledEditIcon
                                icon={
                                  notification.recipients.length === 0
                                    ? `chevron-right`
                                    : `edit`
                                }
                                size="sm"
                              />
                            </span>
                          )}
                        </span>
                        <span tw="block text-lg opacity-60">
                          {renderAlarmNotificationHint(notification)}
                        </span>
                      </AlarmConfigurationComponent>
                    )
                  })}
                {alertNotifications.length === 0 && (
                  <CreateListItem
                    tw="py-4 px-6"
                    onClick={() =>
                      onAddHandler(AlarmSetupPanels.AlertNotificationEditor)
                    }
                  >
                    <span>
                      Ingen modtager(e) af alarm. Tilføj ny modtager +
                    </span>
                  </CreateListItem>
                )}
              </ListGroup>
              <h4 tw="mb-2">
                Tidsplan (valgfrit)
                <HintIcon type={AlarmHintEnum.NoSelectedSchedule} />
              </h4>

              <Hint type={AlarmHintEnum.NoSelectedSchedule} tw="text-xl my-4" />

              <ListGroup as="div" tw="space-y-0">
                {dataModel?.schedule &&
                  dataModel.schedule.length > 0 &&
                  dataModel.schedule.map((schedule) => {
                    const [title, description] = renderScheduleHint(schedule)
                    return (
                      <AlarmConfigurationComponent
                        key={schedule.id}
                        // tw="border-b-0"
                      >
                        <span tw="flex text-2xl">
                          <span tw="flex items-center">
                            <span
                              tw="text-xl font-bold truncate max-w-xs md:max-w-xl"
                              title={title}
                            >
                              {title}
                            </span>
                            {schedule.id !== confirmDeleteID ? (
                              <span
                                tw="flex items-center text-brand-red-300 ml-4 cursor-pointer hover:opacity-60 transition"
                                onClick={() => onDeleteHandler(schedule.id)}
                              >
                                <span tw="mr-2 text-lg">Slet</span>
                                <Icon size={IconSizeEnum.SM} icon="close" />
                              </span>
                            ) : (
                              <>
                                <span
                                  tw="flex items-center ml-4 cursor-pointer hover:opacity-60 transition"
                                  onClick={() => onDeleteHandler()}
                                >
                                  <span tw="mr-2 text-lg">Annullér</span>
                                </span>
                                <span
                                  tw="flex items-center text-brand-red-300 ml-4 cursor-pointer hover:opacity-60 transition"
                                  onClick={() => onConfirmDeleteSchedule()}
                                >
                                  <span tw="mr-2 text-lg">Bekræft</span>
                                </span>
                              </>
                            )}
                          </span>
                          {confirmDeleteID !== schedule?.id && (
                            <span
                              tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
                              onClick={() => onEditScheduleHandler(schedule)}
                            >
                              {schedule.id === currentSchedule?.id ? (
                                <>
                                  <span tw="text-lg text-brand-red-300">
                                    Annullér
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span tw="text-lg">Redigér</span>
                                  <StyledEditIcon icon="edit" size="sm" />
                                </>
                              )}
                            </span>
                          )}
                        </span>
                        <span tw="block text-lg opacity-60">{description}</span>
                      </AlarmConfigurationComponent>
                    )
                  })}
                {dataModel?.schedule.length === 0 && (
                  <CreateListItem
                    tw="py-4 px-6"
                    onClick={() => onEditScheduleHandler()}
                  >
                    <span>Tilføj ny tidsplan +</span>
                  </CreateListItem>
                )}
              </ListGroup>
            </>
          )}
        </div>
        <Hint
          key={AlarmHintEnum.UnsavedChanges}
          tw="mt-8"
          toggled={alarmHasChanges}
          type={AlarmHintEnum.UnsavedChanges}
          variant="warning"
          rememberOnDismiss={false}
        />
        <Responsive
          phone={
            <ButtonGroup tw="bg-white md:hidden mt-16" sticky>
              <Button type="button" variant="default" onClick={onCancel}>
                <span>Annullér</span>
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!alarmHasChanges}
                disabled={saveAlarmDisabled}
              >
                Gem alarm
              </Button>
            </ButtonGroup>
          }
          tabletLandscape={
            <ButtonGroup tw="bg-white md:flex" position="between">
              <Button type="button" variant="transparent" onClick={onCancel}>
                <span>Annullér</span>
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={saveAlarmDisabled}
              >
                Gem alarm
              </Button>
            </ButtonGroup>
          }
        />
      </Form>
    </>
  )
}
