import React from "react"
import styled from "styled-components"
import { Group } from "lib/List/Group"
import { Item } from "lib/List/Item"
import TrackerItem from "app/TrackerItem"
import { useTrackerGroups } from "./hooks"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { useDevices } from "app/Device/hooks"
import useDevicesStore from "app/Device/store"

const StyledListGroup = styled(Group)``

const StyledNestedListGroup = styled(Group)`
  padding-bottom: 1rem;
  position: relative;
  // padding-left: 1rem;
`

const StyledListItem = styled(Item)`
  position: relative;
  opacity: 1;
  //transition: all 0.15s ease-out;

  &.hidden {
    opacity: 0.5;
    height: 0;
    overflow: hidden;
  }

  &.setup-mode {
    height: 41px;
  }

  > span {
    padding: 1rem 0 1rem 2rem;
    font-weight: bold;
    font-size: 1.4rem;

    ${(props) => props.theme.media.tablet_landscape_up`
      transform: translateX(0);
      letter-spacing: 0.08rem;
      padding: 1rem 0 1rem 1.5rem;
    `}
  }

  &:not(:last-child):after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};

    ${(props) => props.theme.media.tablet_landscape_up`
      right: 0;
      left: 0;
    `}
  }
`

const StyledGroups = styled.div``

type TrackerGroupsType = {
  simple: boolean
  hideInvisibleGroups?: boolean
}

const TrackerGroups: React.FC<TrackerGroupsType> = (props) => {
  const { hideInvisibleGroups, simple, ...restProps } = props

  // Get the state and dispatch function from the context
  const {
    deviceGroupVisibilitySetupToggled,
    syncAddress,
    dispatch,
  } = useDevicesStore((state) => ({
    deviceGroupVisibilitySetupToggled: state.deviceGroupVisibilitySetupToggled,
    syncAddress: state.syncAddress,
    dispatch: state.dispatch,
  }))

  const { devices, deviceGroups } = useDevices(syncAddress)
  const { toggleInvisibleTrackerGroup } = useTrackerGroups()

  function onToggleGroup(id) {
    dispatch(DeviceActions(DeviceTypes.ToggleDeviceGroup, { groupID: id }))
  }

  function onToggleGroupVisibility(id) {
    toggleInvisibleTrackerGroup(id)
    dispatch(
      DeviceActions(DeviceTypes.ToggleDeviceGroupVisbility, { groupID: id })
    )
  }

  return (
    <StyledGroups {...restProps}>
      <StyledListGroup>
        {deviceGroups.map((group) => {
          const {
            id,
            name,
            vehicleCount,
            devices: deviceIDs,
            toggled,
            // toggledDevices,
            visible,
          } = group
          return (
            <StyledListItem
              key={id}
              title={`${name} (${vehicleCount})`}
              className={`
                  ${!visible ? `hidden` : ``}
                  ${deviceGroupVisibilitySetupToggled ? ` setup-mode` : ``}
                `}
              onClick={() =>
                deviceGroupVisibilitySetupToggled
                  ? onToggleGroupVisibility(id)
                  : onToggleGroup(id)
              }
              openIcon={deviceGroupVisibilitySetupToggled ? "eye" : "plus"}
              closeIcon={
                deviceGroupVisibilitySetupToggled ? "eye-slash" : "minus"
              }
              toggled={
                (deviceGroupVisibilitySetupToggled && !visible) ||
                (!deviceGroupVisibilitySetupToggled && toggled)
              }
              showIcons={true}
            >
              {!deviceGroupVisibilitySetupToggled && devices && (
                <StyledNestedListGroup>
                  {deviceIDs.map((deviceId) => {
                    const device = devices.find((d) => d.id === deviceId)
                    if (device) {
                      const { id: deviceID } = device
                      return toggled ? (
                        <TrackerItem
                          key={deviceID}
                          groupID={id}
                          deviceID={deviceID}
                          simple={simple}
                          device={device}
                        />
                      ) : null
                    }
                    return null
                  })}
                </StyledNestedListGroup>
              )}
            </StyledListItem>
          )
        })}
      </StyledListGroup>
    </StyledGroups>
  )
}

export default React.memo(TrackerGroups)
