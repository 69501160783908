import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Responsive, Icon, Radar, Tooltip } from "@clevertrack/shared"
import styled from "styled-components"
import {
  PlatformStatusEnum,
  PlatformStatusRed,
  PlatformStatusYellow,
} from "./PlatformStatus/platformstatus.types"
import NavLink from "components/Navigation/NavLink"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { MenuTypeEnum } from "context/App/app.types"
import { NotificationCenterContext } from "./context"
import tw from "twin.macro"
import { NotificationCenter } from "./NotificationCenter"
import Header from "app/Dashboard/Header"
import { IconSizeEnum } from "lib/Icon"
import { NotificationType } from "./notificationcenter.types"
import { useRealtimePlatformHealth } from "services/realtime/platform"
import { Unsubscribe } from "firebase/database"

const StyledPlatformStatus = styled(Radar)`
  width: 1.2rem;
  height: 1.2rem;
  margin-left: -1rem;
  ${(props) =>
    props.showPhoneStatus
      ? `position: relative; transform: none; top: auto;`
      : ``}
  &:before {
    animation-duration: 1.4s;
  }
`

const StyledPlatformStatusNeutral = styled(StyledPlatformStatus)`
  ${tw`text-white`}
`

const StyledPlatformStatusRed = styled(StyledPlatformStatus)`
  ${tw`text-red-400`}
`

const StyledPlatformStatusYellow = styled(StyledPlatformStatus)`
  ${tw`text-yellow-500`}
`

const StyledPlatformStatusGreen = styled(StyledPlatformStatus)`
  &:before {
    animation: none;
  }
  ${tw`text-green-500`}
`

const headerTheme = {
  red: tw`text-white bg-red-400 font-bold z-50`,
  yellow: tw`text-white bg-yellow-500 font-bold z-50`,
  info: tw`text-white bg-brand-blue-50 z-50`,
}

export const NotificationCenterWidget: React.FC<{
  showPhoneStatus: boolean
}> = ({ showPhoneStatus, ...props }) => {
  const {
    state: { toggledMenus, navToggled },
    dispatch,
  } = useContext(AppContext)
  const {
    state: { platformUpdates },
  } = useContext(NotificationCenterContext)

  const systemMessage = useMemo(
    () =>
      platformUpdates.filter(
        (x) => x.category === NotificationType.SYSTEM_MESSAGE && x.isCurrent
      )[0],
    [platformUpdates]
  )

  const handleNotificationsToggle = (menuKey: MenuTypeEnum) => {
    dispatch(
      GlobalAppActions(GlobalAppTypes.ToggleMenu, {
        key: menuKey,
      })
    )
  }
  const [showTooltip, setShowTooltip] = useState(false)

  const onMouseOutHandler = () => {
    setShowTooltip(false)
  }

  const onMouseOverHandler = () => {
    setShowTooltip(true)
  }

  const notificationsToggled = toggledMenus.includes(MenuTypeEnum.Notifications)

  const renderStatusLamp = (notificationType) => {
    const latestUpdate = platformUpdates.filter(
      (x) => x.category === notificationType
    )[0]

    const platformStatus: PlatformStatusEnum =
      !latestUpdate || !latestUpdate.isCurrent
        ? PlatformStatusEnum.OPERATIONAL
        : latestUpdate.platformStatus

    if (PlatformStatusRed.includes(platformStatus)) {
      return (
        <StyledPlatformStatusRed
          showPhoneStatus={showPhoneStatus}
          tw="ml-2 relative transform-none"
        />
      )
    }

    if (PlatformStatusYellow.includes(platformStatus)) {
      return (
        <StyledPlatformStatusYellow
          showPhoneStatus={showPhoneStatus}
          tw="ml-2 relative transform-none"
        />
      )
    }

    return (
      <StyledPlatformStatusGreen
        showPhoneStatus={showPhoneStatus}
        tw="ml-2 relative transform-none"
      />
    )
  }

  const getPlatformStatus = () => {
    const onomondoStatus = platformUpdates.filter(
      (x) => x.category === NotificationType.PLATFORM_STATUS && x.isCurrent
    )[0]
    const dawaStatus = platformUpdates.filter(
      (x) => x.category === NotificationType.ADDRESS_LOOKUP && x.isCurrent
    )[0]

    const platformStatus: PlatformStatusEnum =
      platformUpdates.length === 0 ||
      [onomondoStatus, dawaStatus].filter(Boolean).some((x) => !x.isCurrent)
        ? PlatformStatusEnum.OPERATIONAL
        : [onomondoStatus, dawaStatus].filter(Boolean).find((x) => x.isCurrent)
            ?.platformStatus

    return platformStatus
  }

  const renderPlatformStatus = () => {
    const platformStatus: PlatformStatusEnum = getPlatformStatus()

    if (PlatformStatusRed.includes(platformStatus)) {
      return (
        <span
          tw="relative"
          onMouseOver={onMouseOverHandler}
          onMouseOut={onMouseOutHandler}
        >
          <StyledPlatformStatusRed showPhoneStatus={showPhoneStatus} />
          <Tooltip position="left" toggled={showTooltip}>
            <div tw="flex items-center justify-between">
              <span>Netværk</span>
              {renderStatusLamp(NotificationType.PLATFORM_STATUS)}
            </div>
            <div tw="flex items-center justify-between">
              <span>Adresseopslag</span>
              {renderStatusLamp(NotificationType.ADDRESS_LOOKUP)}
            </div>
          </Tooltip>
        </span>
      )
    }

    if (PlatformStatusYellow.includes(platformStatus)) {
      return (
        <span
          tw="relative"
          onMouseOver={onMouseOverHandler}
          onMouseOut={onMouseOutHandler}
        >
          <StyledPlatformStatusYellow showPhoneStatus={showPhoneStatus} />
          <Tooltip position="left" toggled={showTooltip}>
            <div tw="flex items-center justify-between">
              <span>Netværk</span>
              {renderStatusLamp(NotificationType.PLATFORM_STATUS)}
            </div>
            <div tw="flex items-center justify-between">
              <span>Adresseopslag</span>
              {renderStatusLamp(NotificationType.ADDRESS_LOOKUP)}
            </div>
          </Tooltip>
        </span>
      )
    }

    return (
      <span
        tw="relative"
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
      >
        <StyledPlatformStatusGreen showPhoneStatus={showPhoneStatus} />
        <Tooltip position="left" toggled={showTooltip}>
          <div tw="flex items-center justify-between text-lg p-1">
            <span>Netværk</span>
            {renderStatusLamp(NotificationType.PLATFORM_STATUS)}
          </div>
          <div tw="flex items-center justify-between text-lg p-1">
            <span>Adresseopslag</span>
            {renderStatusLamp(NotificationType.ADDRESS_LOOKUP)}
          </div>
        </Tooltip>
      </span>
    )
  }

  const renderSystemMessage = () => {
    if (!systemMessage) return null
    return (
      <Header
        hasTitle={true}
        tw="sticky left-0 top-0 right-0 bg-white -mt-12 px-4"
        onClick={() => handleNotificationsToggle(MenuTypeEnum.Notifications)}
        css={headerTheme.info}
      >
        <span tw="relative h-full flex items-center justify-center mt-1">
          <span tw="text-lg cursor-pointer">
            <span tw="font-bold">Systemmeddelelse: </span>
            {systemMessage.title}
            <span tw="ml-2 inline-flex items-center">
              - Læs mere <Icon tw="ml-2 w-4 h-4" icon="chevron-right" />
            </span>
          </span>
        </span>
      </Header>
    )
  }

  const subscriptionRef = useRef<Unsubscribe | null>(null)

  const { subscribeToPlatformUpdates } = useRealtimePlatformHealth()

  useEffect(() => {
    if (subscriptionRef.current === null) {
      subscriptionRef.current = subscribeToPlatformUpdates()
    }
    return () => {
      if (subscriptionRef.current) subscriptionRef.current()
    }
  }, [])

  const renderAltPlatformStatus = () => {
    const platformStatus: PlatformStatusEnum = getPlatformStatus()
    const headerThemeToUse = PlatformStatusYellow.includes(platformStatus)
      ? "yellow"
      : PlatformStatusRed.includes(platformStatus)
      ? "red"
      : null

    const currentPlatformUpdate = platformUpdates.find((x) => x.isCurrent)

    if (headerThemeToUse)
      return (
        <>
          <Header
            hasTitle={true}
            tw="absolute left-0 top-0 right-0 bg-white"
            style={{ padding: "0.6rem 0" }}
            onClick={() =>
              handleNotificationsToggle(MenuTypeEnum.Notifications)
            }
            css={headerTheme[headerThemeToUse]}
          >
            <span tw="relative w-full h-full">
              <span tw="text-lg cursor-pointer flex items-center justify-center">
                <StyledPlatformStatusNeutral
                  showPhoneStatus={showPhoneStatus}
                />
                <Icon icon="bell" size={IconSizeEnum.SM} tw="ml-1 mr-4" />
                <span tw="text-lg">{currentPlatformUpdate?.title}</span>
                <span tw="ml-2 inline-flex items-center">
                  - Læs mere <Icon tw="ml-2 w-4 h-4" icon="chevron-right" />
                </span>
              </span>
            </span>
          </Header>
          {renderSystemMessage()}
        </>
      )

    return renderSystemMessage()
  }

  return useMemo(
    () => (
      <Responsive
        phone={
          <>
            {showPhoneStatus ? (
              <>
                <NotificationCenter
                  onClose={() =>
                    handleNotificationsToggle(MenuTypeEnum.Notifications)
                  }
                  {...props}
                />
                {renderAltPlatformStatus()}
              </>
            ) : (
              <>
                <NotificationCenter
                  onClose={() =>
                    handleNotificationsToggle(MenuTypeEnum.Notifications)
                  }
                />
                <NavLink
                  onClick={() =>
                    handleNotificationsToggle(MenuTypeEnum.Notifications)
                  }
                  className={
                    notificationsToggled ? "active icon-only" : "icon-only"
                  }
                  partiallyActive={true}
                  {...props}
                >
                  <span tw="relative space-x-4 flex items-center">
                    {renderPlatformStatus()}
                    <Icon icon="bell" size={IconSizeEnum.MD} />
                  </span>
                </NavLink>
              </>
            )}
          </>
        }
        tabletLandscape={
          showPhoneStatus ? (
            <>
              <NotificationCenter
                onClose={() =>
                  handleNotificationsToggle(MenuTypeEnum.Notifications)
                }
                {...props}
              />
              {renderAltPlatformStatus()}
            </>
          ) : (
            <>
              <NotificationCenter
                onClose={() =>
                  handleNotificationsToggle(MenuTypeEnum.Notifications)
                }
              />
              <NavLink
                onClick={() =>
                  handleNotificationsToggle(MenuTypeEnum.Notifications)
                }
                className={
                  notificationsToggled ? "active icon-only" : "icon-only"
                }
                partiallyActive={true}
              >
                <span tw="relative space-x-4 flex items-center">
                  {renderPlatformStatus()}
                  <Icon icon="bell" />
                </span>
              </NavLink>
            </>
          )
        }
      />
    ),
    [platformUpdates, showTooltip]
  )
}
