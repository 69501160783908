import React, { useState, useMemo, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import cogoToast from "@clevertrackdk/cogo-toast"
import Icon, { IconSizeEnum } from "lib/Icon"
import Checkbox from "lib/Checkbox"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Input,
  Responsive,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"

import FeatureComponent from "app/FeatureComponent"
import FEATURE, { DEFAULTFUNCTIONS } from "data/featureFlags"
import { Select } from "lib/Select"
import Accordion from "lib/Accordion"

import { setVehicleGroups, updateVehicleFeatures } from "services/vehicles"
import {
  updateVehicleCalibration,
  readVehicleCalibration,
} from "services/calibration"

// HACK DEPENDENCIES - COPIED FROM PRIVATEROUTE.JSX
import uniq from "lodash-es/uniq"
import format from "date-fns/format"
import { isEqual, uniqBy } from "lodash-es"

import { useDevices } from "app/Device/hooks"
import { DeviceTypeEnum, IDevice } from "app/Device/types"
import { getDisplayKey } from "app/Device/helper"
import { DeviceTypes, DeviceActions } from "app/Device/actions"
import { AutoOffDislcaimer } from "../AutoOffDisclaimer"
import { DialogModal, Dialog } from "app/Dialog"
import Card from "lib/Card"
import { ModalPage } from "app/Dialog/dialog.types"
import { AppContext } from "context/App"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { useTrackerGroups } from "app/TrackerGroups/hooks"
import useDevicesStore from "app/Device/store"
//HACK END

const StyledForm = styled(Form)`
  min-height: 100%;
  min-width: 100%;
  ${tw`bg-brand-gray-lighter flex flex-col`}
  padding-bottom: 6.5rem;
  padding-top: 2rem;

  section {
    padding: 1rem 2rem;

    h3 {
      margin: 0;
    }
  }

  .description {
    &::placeholder {
      font-size: 1.4rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 1rem;
    padding-bottom: 0;
  `}
`

const Calibration = styled.div<{ toggled: boolean }>`
  cursor: pointer;
  user-select: none;
  margin-top: 1rem;

  > svg {
    margin-left: 0.5rem;
    transition: transform 0.2s;
    transform: rotate(${(props) => (props.toggled ? 90 : 0)}deg);
  }
`

const Specs = styled.div`
  ${tw`space-y-2 mt-8`}
  p {
    ${tw`text-xl leading-snug m-0`}
  }
`

const CheckboxContainer = styled.div`
  padding-top: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

export const CheckIcon = styled(Icon)`
  margin-right: 1rem;
  color: ${(props) => props.theme.colors.gray};
`

const LogIcon = styled.span<{ showAnalogVoltageLog: boolean }>`
  svg {
    ${tw`ml-2 transition-transform origin-center inline-block`}
    ${(props) =>
      props.showAnalogVoltageLog
        ? `transform: rotate(90deg);`
        : `transform: rotate(0deg);`}
  }
`

function Settings({ user, tracker, closePanel, ...props }) {
  const { syncAddress, dispatch } = useDevicesStore((state) => ({
    syncAddress: state.syncAddress,
    dispatch: state.dispatch,
  }))

  const { deviceGroups, updateDeviceValues } = useDevices(syncAddress)
  const {
    dispatch: appDispatch,
    state: { modalPage },
  } = useContext(AppContext)
  const textareaRef = useRef(null)
  const autoOffCheckboxRef = useRef(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [showEngineLowTemperature, setShowEngineLowTemperature] = useState(
    false
  )
  const { getGroups } = useTrackerGroups()

  const setInfoModal = (modal) => {
    appDispatch(GlobalAppActions(GlobalAppTypes.SetCurrentModal, { modal }))
  }

  const [analogVoltageLog, setAnalogVoltageLog] = useState<
    { time: string; value: string }[]
  >([])
  const [showAnalogVoltageLog, setShowAnalogVoltageLog] = useState<boolean>(
    false
  )

  const [currentTracker, setCurrentTracker] = useState<IDevice | null>(null)
  const groupOptions = deviceGroups
    .filter((x) => x.id !== 1)
    .map((x) => ({ label: x.name, value: x.id }))

  const [showCalibration, setShowCalibration] = useState(false)
  const [vehicleData, setVehicleData] = useState(null)

  const fetchData = async () => {
    try {
      const response = await readVehicleCalibration(tracker.id)
      setVehicleData(response.data.vehicle)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData()
    setCurrentTracker(tracker)
    return () => {
      onClosePanel()
    }
  }, [])

  useEffect(() => {
    setCurrentTracker((prev) => ({
      ...prev,
      analog1Voltage: tracker.analog1Voltage,
    }))

    const timestamp = getDisplayKey(
      tracker.values,
      DisplayKeyEnum.LastConfirmationTime
    )

    if (timestamp && timestamp.value) {
      const time = format(new Date(timestamp.value), "HH:mm:ss")
      const newAnalogVoltageLog = uniqBy(
        [{ time, value: tracker.analog1Voltage }, ...analogVoltageLog],
        "time"
      ).slice(0, 10)

      setAnalogVoltageLog(newAnalogVoltageLog)
    }
  }, [tracker])

  useEffect(() => {
    onChangeTracker({
      calibration: {
        mileage: vehicleData?.mileage,
        totalEngineHours: vehicleData?.totalEngineHours,
        IMEI: vehicleData?.imei,
        modulType: vehicleData?.modulType,
      },
    })
  }, [vehicleData])

  const onCancelHandler = async () => {
    if (formRef?.current) formRef.current.reset()
    setCurrentTracker({
      ...tracker,
      calibration: {
        mileage: vehicleData?.mileage,
        totalEngineHours: vehicleData?.totalEngineHours,
        IMEI: vehicleData?.imei,
        modulType: vehicleData?.modulType,
      },
    })
    await closePanel()
  }

  const onClosePanel = async () => {
    await closePanel()
  }

  const onChangeTracker = (obj) => {
    setCurrentTracker((prev) => ({
      ...prev,
      ...obj,
    }))
  }

  const onChangeTrackerChild = (child, obj) => {
    onChangeTracker({
      [child]: {
        ...currentTracker[child],
        ...obj,
      },
    })
  }

  const onSubmitHandler = async (e) => {
    const validateAutoTurnOffTime = (feature) => {
      if (feature) {
        const { autoTurnOff, autoTurnOffTime } = feature
        const time = Number(autoTurnOffTime)
        return autoTurnOff ? time > 1 && Math.round(time) === time : false
      }
      return false
    }

    let featurePromise
    let calibrationPromise
    let vehicleGroupsPromise

    try {
      setLoading(true)

      featurePromise = updateVehicleFeatures(
        currentTracker.id,
        currentTracker?.feature
      )

      if (!isEqual(tracker.group, currentTracker.group)) {
        vehicleGroupsPromise = setVehicleGroups(currentTracker.id, {
          groups: currentTracker.group,
        })
      }

      calibrationPromise = updateVehicleCalibration(currentTracker.id, {
        ...currentTracker?.calibration,
        name: currentTracker.name,
        description: currentTracker?.description,
        note: currentTracker?.note,
        note2: currentTracker?.note2,
      })

      const [featureRes, calibrationRes, vehicleGroupsRes] = await Promise.all(
        [featurePromise, calibrationPromise, vehicleGroupsPromise].filter(
          Boolean
        )
      )

      let canClose = true

      const result: boolean[] = []
      if (vehicleGroupsRes && vehicleGroupsRes.data.result === "OK") {
        result.push(true)
        await getGroups()
      }
      if (featureRes && featureRes.data.result === "OK") result.push(true)
      if (calibrationRes && calibrationRes.result === "OK") result.push(true)

      if (!result.includes(false)) {
        cogoToast.success(`Indstillinger gemt for '${currentTracker?.name}'`)
      } else {
        cogoToast.error(
          `Fejl. Indstillinger ikke gemt for '${currentTracker?.name}'`
        )
        canClose = false
      }

      setLoading(false)

      const { calibration, ...restTracker } = currentTracker

      if (currentTracker) {
        const deviceUpdate = await updateDeviceValues(
          {
            ...restTracker,
            values: restTracker.values.map((dKey) => {
              if (dKey.name === DisplayKeyEnum.TotalDistance) {
                return {
                  ...dKey,
                  value: calibration.mileage,
                }
              }
              if (dKey.name === DisplayKeyEnum.TotalEngineHours) {
                return {
                  ...dKey,
                  value: calibration.totalEngineHours,
                }
              }
              return dKey
            }),
          },
          true
        )

        if (deviceUpdate) {
          dispatch(
            DeviceActions(DeviceTypes.UpdateDeviceByID, {
              device: deviceUpdate,
              id: deviceUpdate.id as number,
            })
          )
        }
      }

      if (canClose) onClosePanel()
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const currentGroupValue = groupOptions.filter((opt) =>
    currentTracker?.group?.includes(opt.value)
  )

  const renderAutoTurnOffTime = () => {
    return (
      <FormField validationKey="autoTurnOff" label="Tomgang min. før motorsluk">
        <ValidationInput
          id="autoTurnOff"
          type="number"
          min="2"
          required={currentTracker?.feature?.autoTurnOff === 1}
          step="1"
          customValidationMessages={{
            valueMissing: "Påkrævet",
            rangeUnderflow: "Min. 2 minutter",
          }}
          placeholder="Indtast min. før motorsluk"
          defaultValue={
            !!Number(tracker?.feature?.autoTurnOffTime)
              ? tracker?.feature?.autoTurnOffTime
              : ""
          }
          onChange={(e) =>
            onChangeTrackerChild("feature", {
              autoTurnOffTime: e.target.value,
            })
          }
        />
      </FormField>
    )
  }

  const renderLowerIdleRPM = () => {
    return (
      <FormField
        label="Grænseværdi ml. tomgang og arbejde (RPM)"
        validationKey="rpm"
      >
        <ValidationInput
          id="rpm"
          type="number"
          min="0"
          required
          placeholder="Indtast motoromdrejninger"
          defaultValue={tracker?.feature?.lowerIdleRPM || ``}
          onChange={(e) =>
            onChangeTrackerChild("feature", {
              lowerIdleRPM: e.target.value,
            })
          }
        />
      </FormField>
    )
  }

  const onAutoTurnOffHandler = (value) => {
    if (value && tracker?.feature?.disclaimerRead === 0) {
      setInfoModal(ModalPage.DISCLAIMER)
    } else {
      onChangeTrackerChild("feature", {
        autoTurnOff: Number(value),
      })
    }
  }

  const onConfirmAutoOffHandler = () => {
    onChangeTrackerChild("feature", {
      autoTurnOff: Number(true),
      disclaimerRead: 1,
      disclaimerReadTimestamp: new Date(),
    })
    setInfoModal(null)
  }

  const memoizedEngineLowTemperature = useMemo(
    () =>
      currentTracker?.feature?.engineLowTemperatur === 0
        ? ""
        : currentTracker?.feature?.engineLowTemperatur,
    [currentTracker]
  )

  const renderEngineLowTemperature = () => {
    return (
      <div>
        <Checkbox
          onChange={(checked) => {
            if (checked) {
              setShowEngineLowTemperature(true)
              onChangeTrackerChild("feature", {
                engineLowTemperatur: memoizedEngineLowTemperature || 0,
              })
            } else {
              setShowEngineLowTemperature(false)
              onChangeTrackerChild("feature", {
                engineLowTemperatur: 0,
              })
            }
          }}
          appearance="toggle"
          checked={showEngineLowTemperature}
          tw="flex mb-4 items-center text-xl"
          size="sm"
        >
          <span tw="flex items-center">
            <CheckIcon icon="thermometer-half" size={IconSizeEnum.SM} />
            <span>Udskyd under koldstart</span>
            <Icon
              onClick={(e) => {
                e.stopPropagation()
                setInfoModal(ModalPage.ENGINELOWTEMPERATURE)
              }}
              tw="cursor-pointer ml-2"
              icon="question-circle"
              size={IconSizeEnum.SM}
            />
          </span>
        </Checkbox>
        <Accordion toggled={showEngineLowTemperature}>
          <FormField
            tw="mb-8"
            label="Påkrævet temperatur inden Auto-sluk"
            validationKey="engineLowTemperature"
          >
            <ValidationInput
              id="engineLowTemperature"
              type="number"
              min="1"
              required={showEngineLowTemperature}
              placeholder="Indtast motortemperatur"
              customValidationMessages={{
                valueMissing: "Påkrævet",
                rangeUnderflow: "Min. 1 grad",
              }}
              defaultValue={
                tracker?.feature?.engineLowTemperatur !== 0
                  ? tracker?.feature?.engineLowTemperatur
                  : ""
              }
              onChange={(e) =>
                onChangeTrackerChild("feature", {
                  engineLowTemperatur: e.target.value,
                })
              }
            />
          </FormField>
        </Accordion>
      </div>
    )
  }

  const renderPressureTransmitterSettings = () => {
    return (
      <>
        <div tw="flex justify-between text-lg mb-2">
          <span tw="block">
            <span>Nuværende spænding: </span>
            <span tw="text-green-600">
              {currentTracker?.analog1Voltage > 0
                ? (currentTracker?.analog1Voltage / 1000).toFixed(3)
                : "0.000"}{" "}
              volt
            </span>
          </span>
          <span
            tw="block ml-auto cursor-pointer transition-colors hover:(text-brand-500)"
            onClick={() => setShowAnalogVoltageLog((prev) => !prev)}
          >
            {showAnalogVoltageLog ? `Skjul målinger` : `Se målinger`}
            <LogIcon {...{ showAnalogVoltageLog }}>
              <Icon icon="chevron-right" size="xs" />
            </LogIcon>
          </span>
        </div>
        <Accordion toggled={showAnalogVoltageLog}>
          <div tw="pb-8">
            {analogVoltageLog
              .sort((a, b) => (a.time > b.time ? -1 : 1))
              .map(({ time, value }, index) => {
                const colorStr = tw`text-green-600`
                return (
                  <span key={`log_${time}_${index}`} tw="text-lg block">
                    <span tw="inline-block mr-24">{`Kl. ${time}:`}</span>
                    <span css={[colorStr]} tw="-ml-1">
                      {(+value / 1000).toFixed(3)} volt
                    </span>
                  </span>
                )
              })}
          </div>
        </Accordion>
        <FormField
          label="Grænseværdi ml. tomgang og arbejde (volt)"
          validationKey="lowerIdleAIN1"
        >
          <ValidationInput
            id="lowerIdleAIN1"
            type="number"
            min="0"
            step=".001"
            placeholder="Indtast volt"
            required
            defaultValue={tracker?.feature?.lowerIdleAIN1 || ``}
            onChange={(e) =>
              onChangeTrackerChild("feature", {
                lowerIdleAIN1: e.target.value,
              })
            }
          />
        </FormField>
        <FormField
          label="Grænseværdi ml. tænding og tomgang (volt)"
          validationKey="zeroIdleAIN1"
        >
          <ValidationInput
            id="zeroIdleAIN1"
            type="number"
            min="0"
            step=".001"
            placeholder="Indtast volt"
            required
            defaultValue={tracker?.feature?.zeroIdleAIN1 || ``}
            onChange={(e) =>
              onChangeTrackerChild("feature", {
                zeroIdleAIN1: e.target.value,
              })
            }
          />
        </FormField>
      </>
    )
  }

  const renderEngineLoadLow = () => {
    return (
      <>
        <FormField
          label="Grænseværdi ml. tomgang og arbejde (%)"
          validationKey="engineLoadLow"
        >
          <ValidationInput
            id="engineLoadLow"
            type="number"
            min="0"
            max="100"
            step=""
            required
            placeholder="Indtast motorbelastning"
            defaultValue={tracker?.feature?.engineLoadLow || ``}
            onChange={(e) =>
              onChangeTrackerChild("feature", {
                engineLoadLow: e.target.value,
              })
            }
          />
        </FormField>
      </>
    )
  }

  const renderAutoTurnOff = () => {
    switch (currentTracker?.feature.idleReadInput) {
      case 1:
      case 3:
        return renderLowerIdleRPM()
      case 2:
        return renderPressureTransmitterSettings()
      case 4:
        return renderEngineLoadLow()
    }
  }

  const renderEngineLowTemperaturComponent = () => {
    switch (currentTracker?.feature.idleReadInput) {
      case 1:
      case 3:
      case 4:
        return !!currentTracker?.feature?.autoTurnOffGen2
          ? renderEngineLowTemperature()
          : null
    }
  }

  const renderDOutOnIgnition = () => {
    return (
      <Checkbox
        onChange={(checked) => {
          if (checked) {
            onChangeTrackerChild("feature", {
              dOutOnIgnition: 1,
            })
          } else {
            onChangeTrackerChild("feature", {
              dOutOnIgnition: 0,
            })
          }
        }}
        size="sm"
        appearance="toggle"
        checked={currentTracker?.feature?.dOutOnIgnition}
        tw="mb-8 mt-6 flex items-center text-xl"
      >
        <span tw="flex items-center">
          <CheckIcon icon="key" size={IconSizeEnum.SM} />
          <span>Fasthold tænding fra</span>
          <Icon
            onClick={(e) => {
              e.stopPropagation()
              setInfoModal(ModalPage.DOUTONIGNITION)
            }}
            tw="cursor-pointer ml-2"
            icon="question-circle"
            size={IconSizeEnum.SM}
          />
        </span>
      </Checkbox>
    )
  }

  const renderDelayAutoTurnOff = () => {
    return (
      <div>
        <Checkbox
          onChange={(checked) => {
            if (checked) {
              onChangeTrackerChild("feature", {
                firstActivation: 1,
              })
            } else {
              onChangeTrackerChild("feature", {
                firstActivation: 0,
              })
            }
          }}
          appearance="toggle"
          checked={currentTracker?.feature?.firstActivation}
          tw="mb-4 flex items-center text-xl"
          size="sm"
        >
          <span tw="flex items-center">
            <CheckIcon icon="timer" size={IconSizeEnum.SM} />
            <span>Udskyd 1. gang dagligt</span>
            <Icon
              onClick={(e) => {
                e.stopPropagation()
                setInfoModal(ModalPage.FIRSTACTIVATION)
              }}
              tw="cursor-pointer ml-2"
              icon="question-circle"
              size={IconSizeEnum.SM}
            />
          </span>
        </Checkbox>
        <Accordion toggled={currentTracker?.feature?.firstActivation === 1}>
          <FormField
            label="Varighed inden Auto-sluk ved 1. start"
            validationKey="firstActivationTime"
          >
            <ValidationInput
              id="firstActivationTime"
              type="number"
              min="2"
              max="120"
              placeholder="Indtast minutter"
              required={currentTracker?.feature?.firstActivation === 1}
              defaultValue={
                tracker?.feature?.firstActivationTime !== 0
                  ? tracker?.feature?.firstActivationTime
                  : null
              }
              customValidationMessages={{
                valueMissing: "Påkrævet",
                rangeUnderflow: "Min. 2 minutter",
              }}
              onChange={(e) =>
                onChangeTrackerChild("feature", {
                  firstActivationTime:
                    e.target.value === "" ? 0 : e.target.value,
                })
              }
            />
          </FormField>
        </Accordion>
        {tracker?.feature?.disclaimerRead === 1 && (
          <span
            tw="text-lg text-brand-500 cursor-pointer"
            onClick={() => setInfoModal(ModalPage.DISCLAIMER)}
          >
            Auto-sluk betingelser accepteret{" "}
            {`${format(
              new Date(tracker?.feature?.disclaimerReadTimestamp),
              "'d.' dd.MM.yyyy 'kl. ' HH:mm"
            )}`}
          </span>
        )}
      </div>
    )
  }

  return (
    <StyledForm forwardRef={formRef} onSubmit={onSubmitHandler} {...props}>
      <section tw="p-0">
        <h3>Enhedsindstillinger</h3>
      </section>

      <section>
        <FeatureComponent user={user} feature={DEFAULTFUNCTIONS.DEVICESETTINGS}>
          <FormField label="Navn" validationKey="name">
            <ValidationInput
              required
              id="name"
              type="text"
              placeholder="Indtast nyt navn"
              defaultValue={tracker?.name}
              onChange={(e) => onChangeTracker({ name: e.target.value })}
            />
          </FormField>
          <FormField label="Beskrivelse">
            <Input
              className="description"
              placeholder="F.eks. nummerplade eller stelnummer"
              defaultValue={tracker?.note}
              onChange={(e) => onChangeTracker({ note: e.target.value })}
            />
          </FormField>
          <FormField label="Grupper">
            <Select
              isClearable={false}
              placeholder="Vælg grupper eller søg"
              isMulti
              noOptionsMessage={() => "Ingen grupper at vælge imellem"}
              options={groupOptions}
              onChange={(opts, action) => {
                onChangeTracker({
                  group: uniq(
                    [...opts.map((x) => x.value)].filter((x) => x !== 1)
                  ),
                })
              }}
              value={currentGroupValue}
            />
          </FormField>
        </FeatureComponent>
        <FormField label="Montering">
          <Textarea
            placeholder="Indtast hvor trackeren er monteret"
            defaultValue={tracker?.description}
            forwardRef={(elm) => (textareaRef.current = elm)}
            onChange={(e) => {
              onChangeTracker({
                description: e.target.value,
              })
            }}
          />
        </FormField>
        {currentTracker?.deviceType !== DeviceTypeEnum.Beacon && (
          <FeatureComponent feature={FEATURE.DEVICESETTINGS} user={user}>
            <Calibration
              toggled={showCalibration}
              onClick={() => {
                setShowCalibration(!showCalibration)
              }}
            >
              Kalibrering <Icon icon="chevron-right" size={IconSizeEnum.SM} />
            </Calibration>
            <Accordion toggled={showCalibration}>
              <FormField tw="mt-2" label="Kilometertæller (km)">
                <Input
                  type="number"
                  step=".01"
                  min="0"
                  placeholder="Indtast kilometer"
                  value={currentTracker?.calibration?.mileage}
                  onChange={(e) =>
                    onChangeTrackerChild("calibration", {
                      mileage: e.target.value,
                    })
                  }
                />
              </FormField>
              <FormField label="Motortimer (timer)">
                <Input
                  type="number"
                  step=".01"
                  min="0"
                  placeholder="Indtast motortimer"
                  value={currentTracker?.calibration?.totalEngineHours}
                  onChange={(e) =>
                    onChangeTrackerChild("calibration", {
                      totalEngineHours: e.target.value,
                    })
                  }
                />
              </FormField>
              {[1, 2, 3, 4].includes(currentTracker?.feature.idleReadInput) && (
                <h4 tw="mt-6 mb-4 text-2xl">Tomgangsberegning</h4>
              )}
              {renderAutoTurnOff()}
            </Accordion>
          </FeatureComponent>
        )}
        {currentTracker?.feature?.showImmobilizer ||
        currentTracker?.feature?.showAutoTurnOff ? (
          <CheckboxContainer>
            {!!currentTracker?.feature?.showImmobilizer && (
              <Checkbox
                // onChange={(value) => onCheckHandler(value, setStartBlocker)}
                onChange={(value) =>
                  onChangeTrackerChild("feature", {
                    immobilizer: Number(value),
                  })
                }
                appearance="toggle"
                checked={currentTracker?.feature?.immobilizer}
                tw={"flex items-center"}
              >
                <CheckIcon icon="lock-alt-solid" />
                <span>Startspærre</span>
              </Checkbox>
            )}
            {!!currentTracker?.feature?.showAutoTurnOff && (
              <>
                <Checkbox
                  // onChange={(value) => onCheckHandler(value, setAutoKill)}

                  onChange={(value) => onAutoTurnOffHandler(value)}
                  appearance="toggle"
                  checked={currentTracker?.feature?.autoTurnOff}
                  tw="mb-4 mt-4 flex items-center"
                >
                  <span tw="flex items-center" ref={autoOffCheckboxRef}>
                    <CheckIcon icon="auto" />
                    <span>Auto-sluk</span>
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation()
                        setInfoModal(ModalPage.ABOUT)
                      }}
                      tw="cursor-pointer ml-2"
                      icon="question-circle"
                      size={IconSizeEnum.SM}
                    />
                  </span>
                </Checkbox>
                <Accordion toggled={currentTracker?.feature?.autoTurnOff === 1}>
                  {renderAutoTurnOffTime()}
                  <div tw="mt-4">{renderDOutOnIgnition()}</div>
                  <h4 tw="my-0 mb-4 text-xl">Motoropvarmningsfunktioner</h4>
                  {renderEngineLowTemperaturComponent()}
                  {renderDelayAutoTurnOff()}
                </Accordion>
              </>
            )}
          </CheckboxContainer>
        ) : null}
        <Specs>
          <p>
            <strong>Enhedstype: </strong>
            {currentTracker?.calibration?.modulType}
          </p>
          <p>
            <strong>
              {currentTracker?.deviceType === DeviceTypeEnum.Beacon
                ? "ID: "
                : "IMEI: "}
            </strong>
            {currentTracker?.calibration?.IMEI}
          </p>
          {user.username.includes("@clevertrack.dk") && currentTracker && (
            <small tw="block">
              DeviceID:{" "}
              <a
                target="_blank"
                href={`https://console.firebase.google.com/u/0/project/clevertrack-282807/database/clevertrack-282807-default-rtdb/data/~2Fvehicles~2F${currentTracker.id}`}
              >
                {currentTracker.id}
              </a>
            </small>
          )}
        </Specs>
      </section>
      <DialogModal open={!!modalPage} onClose={() => setInfoModal(null)}>
        <Dialog.Panel tw="mx-auto my-auto">
          <Card tw="bg-white p-8">
            <AutoOffDislcaimer
              onChangeDOutOnIgnition={(checked) => {
                if (checked) {
                  onChangeTrackerChild("feature", {
                    dOutOnIgnition: 1,
                  })
                } else {
                  onChangeTrackerChild("feature", {
                    dOutOnIgnition: 0,
                  })
                }
              }}
              dOutOnIgnitionChecked={currentTracker?.feature?.dOutOnIgnition}
            >
              {!tracker?.feature?.disclaimerRead ? (
                <Responsive
                  phone={
                    <ButtonGroup position="between">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={onConfirmAutoOffHandler}
                      >
                        Aktivér Auto-sluk
                      </Button>
                      <Button
                        variant="default"
                        size="sm"
                        onClick={() => setInfoModal(null)}
                      >
                        Annuller
                      </Button>
                    </ButtonGroup>
                  }
                  tabletLandscape={
                    <ButtonGroup position="between">
                      <Button
                        variant="primary"
                        onClick={onConfirmAutoOffHandler}
                      >
                        Aktivér Auto-sluk
                      </Button>
                      <Button
                        variant="default"
                        onClick={() => setInfoModal(null)}
                      >
                        Annuller
                      </Button>
                    </ButtonGroup>
                  }
                />
              ) : (
                <Responsive
                  phone={
                    <>
                      <span tw="block text-brand-gray-dark opacity-60 text-center text-xl pt-8 border-solid border-0 border-t ">
                        Auto-sluk betingelser accepteret{" "}
                        {`${format(
                          new Date(tracker?.feature?.disclaimerReadTimestamp),
                          "'d.' dd.MM.yyyy 'kl. ' HH:mm"
                        )}`}
                      </span>
                      <ButtonGroup position="between">
                        <Button
                          variant="default"
                          size="sm"
                          onClick={() => setInfoModal(null)}
                        >
                          Luk
                        </Button>
                      </ButtonGroup>
                    </>
                  }
                  tabletLandscape={
                    <>
                      <span tw="block text-brand-gray-dark opacity-60 text-center text-xl pt-4 mt-4 border-solid border-0 border-t ">
                        Auto-sluk betingelser accepteret{" "}
                        {`${format(
                          new Date(tracker?.feature?.disclaimerReadTimestamp),
                          "'d.' dd.MM.yyyy 'kl. ' HH:mm"
                        )}`}
                      </span>
                      <ButtonGroup position="between">
                        <Button
                          variant="default"
                          onClick={() => setInfoModal(null)}
                        >
                          Luk
                        </Button>
                      </ButtonGroup>
                    </>
                  }
                />
              )}
            </AutoOffDislcaimer>
          </Card>
        </Dialog.Panel>
      </DialogModal>
      <Responsive
        phone={
          <ButtonGroup
            position="between"
            sticky
            tw="md:hidden px-8 mt-auto bg-brand-gray-lighter"
          >
            <Button
              disabled={loading}
              type="button"
              variant="default"
              onClick={onCancelHandler}
            >
              Annuller
            </Button>
            <Button disabled={loading} variant="primary" type="submit">
              {loading ? "Gemmer..." : "Gem"}
            </Button>
          </ButtonGroup>
        }
        tabletLandscape={
          <ButtonGroup
            position="between"
            tw="hidden md:(flex mt-8 px-8) bg-brand-gray-lighter"
          >
            <Button
              type="button"
              variant="default"
              tw="w-1/2 px-0"
              disabled={loading}
              onClick={onCancelHandler}
            >
              Annuller
            </Button>
            <Button
              variant="primary"
              tw="w-1/2 px-0"
              type="submit"
              disabled={loading}
            >
              {loading ? "Gemmer..." : "Gem"}
            </Button>
          </ButtonGroup>
        }
      />
    </StyledForm>
  )
}

export default Settings

Settings.defaultProps = {}
Settings.propTypes = {}
