import {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { UserContext } from "app/User/context"
import { DataSnapshot, Unsubscribe } from "firebase/database"
import { isEqual } from "lodash-es"
import { useFirestoreCompany } from "services/firestore/company"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { updateAssignedTrackersAndGroupsByUserId } from "services/user"
import { getAllVehicles, getVehiclesInGroup } from "services/vehicles"
import { DeviceActions, DeviceTypes } from "./actions"
import {
  assertDeviceCANAvailability,
  assertDeviceTypeByAvailableDisplayKeys,
  getDisplayKey,
} from "./helper"
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  FirestoreDevice,
  IDevice,
  IDeviceGroup,
  IRawDevice,
  IRawDeviceGroup,
} from "./types"
import { CompanyContext } from "app/Company/context"
import useDevicesStore from "./store"

type DeviceSubscriptions = {
  id: string
  unsub: Unsubscribe
}

const useDevices = (subscriptionActive = true) => {
  const {
    devices,
    deviceGroups,
    toggledDevices,
    toggledDeviceGroups,
    dispatch,
  } = useDevicesStore((state) => ({
    devices: state.devices,
    deviceGroups: state.deviceGroups,
    toggledDevices: state.toggledDevices,
    toggledDeviceGroups: state.toggledDeviceGroups,
    dispatch: state.dispatch,
  }))

  const [lastUpdate, setLastUpdate] = useState({})

  const {
    state: {
      company: { id: companyID },
    },
  } = useContext(CompanyContext)
  const {
    state: { user, userSettings },
  } = useContext(UserContext)
  const {
    subscribeToDeviceByID,
    getDeviceValuesByCompanyID,
    getDeviceValues,
  } = useRealtimeDeviceValues()
  const {
    saveFirebaseCompanyDevices,
    saveFirebaseCompanyDeviceGroups,
  } = useFirestoreCompany()
  const [
    vehiclesAndGroupsInProgress,
    setVehiclesAndGroupsInProgress,
  ] = useState(false)
  const deviceSubscriptions = useRef<DeviceSubscriptions>(null)

  useLayoutEffect(() => {
    const newToggledDevices = devices.filter((device) => device.toggled)
    const newToggledGroups = deviceGroups.filter((group) => group.toggled)
    if (!isEqual(toggledDevices, newToggledDevices)) {
      dispatch(
        DeviceActions(DeviceTypes.SetToggledDevices, {
          devices: newToggledDevices,
        })
      )
    }

    if (!isEqual(toggledDeviceGroups, newToggledGroups)) {
      dispatch(
        DeviceActions(DeviceTypes.SetToggledDeviceGroups, {
          groups: newToggledGroups,
        })
      )
    }
  }, [devices, deviceGroups])

  const setDevicesAndGroups = async (
    {
      groups,
      vehicles,
    }: {
      groups: IRawDeviceGroup[]
      vehicles: IRawDevice[]
    },
    fresh: boolean
  ) => {
    if (fresh) {
      const newDevices: IDevice[] = vehicles.map((vehicle) => {
        const { data, ...restVehicle } = vehicle
        return {
          ...restVehicle,
          deviceHasCAN: assertDeviceCANAvailability(data),
          deviceType: assertDeviceTypeByAvailableDisplayKeys(data),
          values: data,
          toggled: toggledDevices.map((x) => x.id).includes(vehicle.id), // Untoggled by default
          toggledInGroups: [],
        }
      })
      const newGroups: IDeviceGroup[] = groups.map((group) => ({
        ...group,
        devices: newDevices
          .filter((device) => device.group.includes(group.id))
          .map((device) => device.id),
        toggled: toggledDeviceGroups.map((x) => x.id).includes(group.id),
        visible: !userSettings?.invisibleGroups?.includes(group.id),
      }))

      dispatch(
        DeviceActions(DeviceTypes.SetDeviceGroups, { groups: newGroups })
      )
      dispatch(DeviceActions(DeviceTypes.SetDevices, { devices: newDevices }))

      newDevices
        .filter((device) => +device.status === DeviceStatusEnum.UNKNOWN)
        .map((device) => updateDeviceValues(device, true))

      const updateCompanyDevices: FirestoreDevice[] = []
      for (const device of newDevices) {
        updateCompanyDevices.push({
          id: device.id,
          name: device.name,
        })
      }

      await saveFirebaseCompanyDevices(updateCompanyDevices)
      await saveFirebaseCompanyDeviceGroups(newGroups)
    }
  }

  const resetDevicesAndGroups = useCallback(async () => {
    const res = await unsubscribeToDevices()
    dispatch(DeviceActions(DeviceTypes.Reset, null))
    return res
  }, [])

  const populateVehiclesAndGroups = async (fresh: boolean = false) => {
    try {
      if (!vehiclesAndGroupsInProgress) {
        if (deviceGroups.length > 0 && devices.length > 0) {
          await setDevicesAndGroups(
            { groups: deviceGroups, vehicles: devices },
            fresh
          )
        } else if (deviceGroups.length > 0 && devices.length === 0) {
          // In this scenario, the user lost their assigned vehicles.
          // Reassign the vehicles to the user
          const userDeviceGroups = deviceGroups.map((group) => group.id)
          const devicesInGroupsPromises = await Promise.all(
            deviceGroups.map((group) => getVehiclesInGroup(group.id))
          )

          const devicesInGroups = devicesInGroupsPromises.flatMap(
            (res) => res?.data.vehicles
          )

          const payload = {
            vehicles: devicesInGroups,
            groups: userDeviceGroups,
          }

          if (devicesInGroups.length > 0) {
            const updateRes = await updateAssignedTrackersAndGroupsByUserId(
              user?.id,
              payload
            )

            if (updateRes && updateRes.data.result === "OK") {
              await refreshVehiclesAndGroups()
            }
          }
        } else {
          await refreshVehiclesAndGroups()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const refreshVehiclesAndGroups = async () => {
    try {
      if (deviceGroups.length === 0 && devices.length === 0) {
        setVehiclesAndGroupsInProgress(true)
        const vehiclesResponse = await getAllVehicles()
        if (vehiclesResponse && vehiclesResponse.data.result === "OK") {
          setDevicesAndGroups({ ...vehiclesResponse.data }, true)
          setVehiclesAndGroupsInProgress(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateDeviceValues = (
    data: Partial<IDevice>,
    updateOffline?: boolean,
    sync: boolean = false
  ) => {
    if (data && !!data?.values && data.id) {
      const deviceUpdate = {
        ...data,
        values: [
          ...data.values.filter((x) => x.name !== DisplayKeyEnum.Address),
        ].filter(Boolean),
      }

      return deviceUpdate
    } else {
      console.log("device updated voided")
      return null
    }
  }

  const updateDeviceValuesBySnapshot = (snapshot: DataSnapshot) => {
    const data = snapshot.val()
    if (data) {
      const updates = Object.entries(data).map(([id, data]) => {
        return {
          ...data,
          values: data?.values
            ? [
                ...data.values.filter((x) => x.name !== DisplayKeyEnum.Address),
              ].filter(Boolean)
            : [],
        }
      })

      dispatch(
        DeviceActions(DeviceTypes.UpdateManyDevices, { devices: updates })
      )
    }
  }

  const subscribeToDevices = () => {
    // dispatch(DeviceActions(DeviceTypes.SetLoading, true))
    // Get all deviceID's of the current account
    /* const devicesInToggledGroups = toggledDeviceGroups.flatMap((x) => x.devices)
    const devicesToggled = toggledDevices.map((x) => x.id)
    const deviceIDs = [...devicesInToggledGroups, ...devicesToggled] */

    // If there's any devices we're not yet subscribing to
    const newSubscriptions: DeviceSubscriptions = getDeviceValuesByCompanyID(
      companyID,
      updateDeviceValuesBySnapshot
    )
    // Add new subscriptions to ref
    deviceSubscriptions.current = {
      id: "all",
      unsub: newSubscriptions,
    }
    // dispatch(DeviceActions(DeviceTypes.SetLoading, false))
  }

  const unsubscribeToDevices = () => {
    if (deviceSubscriptions.current) {
      deviceSubscriptions.current?.unsub()
      deviceSubscriptions.current = null
    }
  }

  const showDeviceHealth = (device: IDevice) => {
    const {
      externalVoltageLost,
      GPSFailure,
      values,
      status,
      batteryLongevity,
      batteryLongevityVolt72,
    } = device
    let lostGSMSignal
    const currentTime = +new Date()

    if (values && [1, 2, 3, 4].includes(+status)) {
      const treshold = 60 * 5 * 1000 // 5 Minutes
      const lastConfirmationTimestamp = getDisplayKey(
        values,
        DisplayKeyEnum.LastConfirmationTime
      )
      const lastSignal = lastConfirmationTimestamp?.value
        ? +new Date(lastConfirmationTimestamp.value)
        : null

      if (lastSignal) {
        lostGSMSignal = currentTime - lastSignal > treshold
      }
    }

    if (!!GPSFailure || lostGSMSignal) {
      return true
    }

    if (
      [
        DeviceTypeEnum.Machine,
        DeviceTypeEnum.Car,
        DeviceTypeEnum.MaterialScanner,
      ].includes(device.deviceType)
    ) {
      return !!externalVoltageLost
    }

    if ([DeviceTypeEnum.Beacon].includes(device.deviceType)) {
      return batteryLongevity < 15
    }

    if ([DeviceTypeEnum.Asset].includes(device.deviceType)) {
      return batteryLongevityVolt72 < 15
    }

    return false
  }

  return {
    updateDeviceValues,
    setDevicesAndGroups,
    resetDevicesAndGroups,
    populateVehiclesAndGroups,
    refreshVehiclesAndGroups,
    subscribeToDevices,
    unsubscribeToDevices,
    showDeviceHealth,
    subscriptionActive: !!deviceSubscriptions.current,
    devices,
    deviceGroups,
    toggledGroups: toggledDeviceGroups,
    toggledDevices,
  }
}

export { useDevices }
