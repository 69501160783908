import { create } from "zustand"
import { uniqBy } from "lodash-es"
import { toggleItemInEnumArray, updateCollectionItemByIndex } from "utils/array"
import { DeviceActionMap, DeviceTypes } from "./actions"
import { IDevice, IDeviceAlarm, IDeviceGroup } from "./types"
import { getDisplayKey } from "./helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"

type DevicesState = {
  syncAddress: boolean
  devices: IDevice[]
  deviceGroups: IDeviceGroup[]
  toggledDevices: IDevice[]
  toggledDeviceGroups: IDeviceGroup[]
  toggledDeviceAlarms: IDeviceAlarm[]
  deviceGroupVisibilitySetupToggled: boolean
  deviceSubscriptionIDs: number[]
  animating: boolean
  loading: boolean
  showCurrentRoute: boolean
}

type DevicesActions = {
  dispatch: (action: DeviceActionMap) => void
}

const initialState: DevicesState = {
  syncAddress: false,
  devices: [],
  deviceGroups: [],
  toggledDevices: [],
  toggledDeviceGroups: [],
  toggledDeviceAlarms: [],
  deviceGroupVisibilitySetupToggled: false,
  deviceSubscriptionIDs: [],
  animating: false,
  loading: false,
  showCurrentRoute: true,
}

const useDevicesStore = create<DevicesState & DevicesActions>((set, get) => ({
  ...initialState,
  dispatch: (action) => {
    const state = get()

    switch (action.type) {
      case DeviceTypes.SetSyncAddress:
        set({ syncAddress: action.payload.sync })
        break

      case DeviceTypes.SetDevices:
        set({ devices: action.payload.devices })
        break

      case DeviceTypes.SetDeviceGroups:
        set({ deviceGroups: action.payload.groups })
        break

      case DeviceTypes.SetAnimating:
        set({ animating: action.payload })
        break

      case DeviceTypes.SetLoading:
        set({ loading: action.payload })
        break

      case DeviceTypes.SetToggledDevices:
        set({ toggledDevices: action.payload.devices })
        break

      case DeviceTypes.SetToggledDeviceGroups:
        set({ toggledDeviceGroups: action.payload.groups })
        break

      case DeviceTypes.ToggleShowCurrentRoute:
        set({ showCurrentRoute: action.payload })
        break

      case DeviceTypes.ToggleGroupVisibilitySetup:
        set({
          deviceGroupVisibilitySetupToggled: !state.deviceGroupVisibilitySetupToggled,
        })
        break

      case DeviceTypes.ToggleDeviceGroup: {
        const toggleDeviceGroup = state.deviceGroups.find(
          (grp) => grp.id === action.payload.groupID
        )

        if (toggleDeviceGroup) {
          const newGroups = updateCollectionItemByIndex<IDeviceGroup>(
            state.deviceGroups,
            action.payload.groupID,
            { ...toggleDeviceGroup, toggled: !toggleDeviceGroup.toggled }
          )

          const relatedDeviceIDMap = toggleDeviceGroup.devices
          const newDevices = state.devices.map((device) => {
            if (relatedDeviceIDMap.includes(device.id)) {
              return {
                ...device,
                toggled: toggleDeviceGroup.toggled ? false : device.toggled,
              }
            }
            return device
          })

          set({ deviceGroups: newGroups, devices: newDevices })
        }
        break
      }

      case DeviceTypes.ToggleDeviceGroupVisbility: {
        const toggleDeviceGroupVisibility = state.deviceGroups.find(
          (grp) => grp.id === action.payload.groupID
        )

        if (toggleDeviceGroupVisibility) {
          const newGroups = updateCollectionItemByIndex<IDeviceGroup>(
            state.deviceGroups,
            action.payload.groupID,
            {
              ...toggleDeviceGroupVisibility,
              visible: !toggleDeviceGroupVisibility.visible,
            }
          )

          set({ deviceGroups: newGroups })
        }
        break
      }

      case DeviceTypes.ToggleDeviceInGroup: {
        const toggleDevice = state.devices.find(
          (device) => device.id === action.payload.deviceID
        )

        if (toggleDevice) {
          const newStateDevices = action.payload.untoggleRest
            ? state.devices.map((device) => ({
                ...device,
                toggled: false,
                toggledInGroups: [action.payload.groupID],
              }))
            : state.devices

          const newDevices = updateCollectionItemByIndex<IDevice>(
            newStateDevices,
            action.payload.deviceID,
            {
              ...toggleDevice,
              toggled: !toggleDevice.toggled,
              toggledInGroups: toggleDevice.toggledInGroups.includes(
                action.payload.groupID
              )
                ? toggleDevice.toggledInGroups.filter(
                    (groupID) => groupID !== action.payload.groupID
                  )
                : [...toggleDevice.toggledInGroups, action.payload.groupID],
            }
          )

          set({ devices: newDevices })
        }
        break
      }

      case DeviceTypes.ToggleDeviceSubscription:
        set({
          deviceSubscriptionIDs: toggleItemInEnumArray(
            state.deviceSubscriptionIDs,
            action.payload.deviceID
          ),
        })
        break

      case DeviceTypes.UpdateDeviceByID: {
        const updateDevice = state.devices.find(
          (device) => device.id === action.payload.id
        )

        if (updateDevice) {
          const newUpdatedDevices = updateCollectionItemByIndex<IDevice>(
            [...state.devices],
            action.payload.id,
            { ...updateDevice, ...action.payload.device }
          )

          set({ devices: newUpdatedDevices })
        }
        break
      }

      case DeviceTypes.UpdateManyDevices: {
        const devicesPendingUpdate = action.payload.devices.map((x) => x.id)
        const updatedDevices = action.payload.devices
          .map((device) => {
            const findDeviceToUpdate = state.devices.find(
              (oldDevice) => oldDevice.id === device.id
            )

            if (findDeviceToUpdate) {
              const address = getDisplayKey(
                findDeviceToUpdate?.values,
                DisplayKeyEnum.Address
              )

              return {
                ...findDeviceToUpdate,
                ...device,
                values: findDeviceToUpdate
                  ? [...device.values, address]
                  : device.values,
              }
            }

            return null
          })
          .filter(Boolean)

        set({
          devices: [
            ...state.devices.filter(
              (dvc) => !devicesPendingUpdate.includes(dvc.id)
            ),
            ...updatedDevices,
          ],
        })
        break
      }

      case DeviceTypes.UntoggleAllDevices:
        set({
          devices: state.devices.map((device) => ({
            ...device,
            toggled: false,
            toggledInGroups: [],
          })),
          deviceGroups: state.deviceGroups.map((deviceGroup) => ({
            ...deviceGroup,
            toggled: false,
          })),
        })
        break

      case DeviceTypes.AddDeviceAlarm:
        set({
          toggledDeviceAlarms: uniqBy(
            [
              ...state.toggledDeviceAlarms,
              {
                alertID: action.payload.alarmID,
                deviceID: action.payload.deviceID,
              },
            ],
            "alarmID"
          ),
        })
        break

      case DeviceTypes.Reset:
        set({ ...initialState })
        break

      default:
        break
    }
  },
}))

export default useDevicesStore
