import React, { useMemo, useState } from "react"
import styled from "styled-components"
import intersectionBy from "lodash-es/intersectionBy"
import maxBy from "lodash-es/maxBy"
import GoogleMap from "app/GoogleMap"
import Cluster from "app/GoogleMap/Cluster"
import Point from "app/GoogleMap/Point"
import { getClusters } from "app/GoogleMap/helper"
import useDevicesStore from "app/Device/store"

const StyledServiceMap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  // padding-bottom: 6.2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-bottom: 0;
  `}
`

function ServiceMap({ points, ...props }) {
  const { devices } = useDevicesStore((state) => ({
    devices: state.devices,
  }))

  const [mapOptions, setMapOptions] = useState({})
  const { geoPoints } = useMemo(() => {
    const intersectionPoints = intersectionBy(devices, points, "id").map(
      (x) => {
        return { id: x.id, position: x.position }
      }
    )
    const merged = intersectionPoints.map((point) => {
      return {
        ...point,
        ...points.find((x) => x.id === point.id),
      }
    })
    const geoPoints = merged.map((x) => {
      // console.log(x)
      const status = maxBy(x.intervals, "index")
      // console.log(status)
      return {
        ...x.position,
        properties: {
          ...x.position.properties,
          status: status.index >= 95 ? 0 : 2,
          index: status.index,
          name: x.name,
        },
      }
    })
    return { geoPoints }
  }, [points])

  function onChange({ bounds, zoom }) {
    // Here, we get the initial bounds and zoom of the Google Map.
    // If any trackers are selected, we calculate the bounds based on those points instead.
    setMapOptions({ bounds, zoom })
  }

  function getPointsAndClusters() {
    if (mapOptions.bounds && mapOptions.zoom) {
      // Creates renderable map markers based on the Trackers collection
      const { nw, se, sw, ne } = mapOptions.bounds
      const superclusterBounds = [nw.lng, sw.lat, se.lng, ne.lat]
      const { _instance, clusters } = getClusters(
        geoPoints,
        superclusterBounds,
        mapOptions.zoom
      )
      return { clusters, cluster: _instance }
    }
  }

  function renderPointsAndClusters() {
    const ptsAndClusters = getPointsAndClusters()
    if (!ptsAndClusters) return null
    const { clusters: items, cluster } = ptsAndClusters
    return items.length > 0
      ? items.map((item, i) => {
          const {
            properties,
            geometry: { coordinates },
          } = item
          // console.log(properties)
          const [lng, lat] = coordinates
          return properties.cluster ? (
            <Cluster
              key={`cluster_${i}`}
              cluster={cluster}
              lat={lat}
              lng={lng}
              // hideInformation={true}
              {...item}
            />
          ) : (
            <Point
              key={`point_${i}`}
              lat={lat}
              lng={lng}
              // hideInfo={true}
              {...item}
            />
          )
        })
      : null
  }

  return (
    <StyledServiceMap {...props}>
      <GoogleMap onChange={onChange} geoPoints={geoPoints} forceRefresh>
        {geoPoints && geoPoints.length > 0 && renderPointsAndClusters()}
      </GoogleMap>
    </StyledServiceMap>
  )
}

export default ServiceMap

ServiceMap.defaultProps = {}
ServiceMap.propTypes = {}
