import React, { useContext } from "react"
import "twin.macro"
import Search, { ISearchProps } from "app/Search"
import { SearchContext } from "app/Search/context"
import { freetextSearch } from "app/Search/helper"
import { useConversation } from "../hooks"

export const ContactSearch: React.FC<Partial<ISearchProps>> = ({
  placeholder,
  wrapperProps,
  data,
  ...props
}) => {
  const {
    state: { query },
  } = useContext(SearchContext)
  const { contactsAndGroupsSorted } = useConversation()

  const dataset = freetextSearch(data || contactsAndGroupsSorted, {
    threshold: 0.25,
    location: 0,
    distance: 88,
    ignoreLocation: query.length > 1,
    includeScore: true,
    keys: ["first_name", "last_name", "title", "phone", "email"],
  })

  return (
    <div tw="px-4 bg-white" {...wrapperProps}>
      <Search placeholder={placeholder} dataset={dataset} {...props} />
    </div>
  )
}
