import { create } from "zustand"
import { toggleItemInEnumArray, updateCollectionItemByID } from "utils/array"
import {
  IContact,
  ConversationRoutes,
  PopOverRoutesEnum,
  IContactGroup,
  IContactOrContactGroup,
  IResolvedConversation,
} from "./conversations.types"
import { ConversationActionMap, ConversationTypes } from "./actions"
import uniqBy from "lodash-es/uniqBy"
import orderBy from "lodash-es/orderBy"

type ConversationState = {
  toggledRoutes: ConversationRoutes[]
  contacts: IContact[]
  groups: IContactGroup[]
  conversations: IResolvedConversation[]
  editContact: IContact | null
  editGroup: IContactGroup | null
  currentConversationID: string
  selectedContacts: IContactOrContactGroup[]
  message: string
  goToMessageID: number | null
  loading: boolean
  subscriptionActive: boolean
}

type ConversationActions = {
  dispatch: (action: ConversationActionMap) => void
}

const initialState: ConversationState = {
  toggledRoutes: [],
  contacts: [],
  groups: [],
  conversations: [],
  editContact: null,
  editGroup: null,
  currentConversationID: "",
  selectedContacts: [],
  message: "",
  goToMessageID: null,
  loading: false,
  subscriptionActive: false,
}

const useConversationStore = create<ConversationState & ConversationActions>(
  (set, get) => ({
    ...initialState,
    dispatch: (action) => {
      const state = get()

      switch (action.type) {
        case ConversationTypes.ToggleRoute:
          set({
            toggledRoutes: toggleItemInEnumArray(
              state.toggledRoutes,
              action.payload.key
            ),
          })
          break

        case ConversationTypes.SetRoute:
          set({ toggledRoutes: [action.payload.key] })
          break

        case ConversationTypes.SetRoutes:
          set({ toggledRoutes: action.payload.routes })
          break

        case ConversationTypes.EditContact:
          set({
            editContact: action.payload.contact,
            toggledRoutes: toggleItemInEnumArray(
              state.toggledRoutes,
              PopOverRoutesEnum.EditContact
            ),
          })
          break

        case ConversationTypes.EditGroup:
          set({
            editGroup: action.payload.group,
            toggledRoutes: toggleItemInEnumArray(
              state.toggledRoutes,
              PopOverRoutesEnum.EditGroup
            ),
          })
          break

        case ConversationTypes.SetContacts: {
          const newContacts = action.payload.contacts.map((contact) => {
            const contactID = contact.id
            const previousContact = state.contacts.find(
              (c) => c.id === contactID
            )
            return {
              ...contact,
              last_read_by: {
                ...previousContact?.last_read_by,
                ...contact.last_read_by,
              },
            }
          })

          const newConversations = state.conversations.map((convo) => {
            const newConversationContact = action.payload.contacts.find(
              (contact) => contact.id === convo.id
            )
            return {
              ...convo,
              contact: newConversationContact,
            }
          })

          set({ contacts: newContacts, conversations: newConversations })
          break
        }

        case ConversationTypes.AddContact:
          set({ contacts: [...state.contacts, action.payload.contact] })
          break

        case ConversationTypes.AddConversation:
          set({
            conversations: uniqBy(
              [...state.conversations, action.payload.newConversation],
              "id"
            ),
          })
          break

        case ConversationTypes.SetConversations:
          set({ conversations: action.payload.conversations })
          break

        case ConversationTypes.UpdateConversationByID: {
          const conversation = state.conversations.find(
            (convo) => convo.id === action.payload.conversation.id
          )

          if (conversation) {
            const contact = state.contacts.find((c) => c.id === conversation.id)
            const conversationMessages = uniqBy(
              [
                ...action.payload.conversation.messages,
                ...conversation.messages,
              ],
              (x) => x.internal_id || x.id
            )

            if (contact) {
              const unreadCount = conversationMessages.filter((x) => {
                if (action.payload.conversation.contact.last_read_by) {
                  return (
                    x.received &&
                    action.payload.conversation.contact.last_read_by[
                      action.payload.userID
                    ] < x.time
                  )
                } else {
                  return true
                }
              }).length

              conversation.unread_count = unreadCount
            }

            if (conversationMessages.length >= conversation.messages.length) {
              const updatedConversation = {
                ...conversation,
                contact: action.payload.conversation.contact,
                messages: orderBy(conversationMessages, "time", "asc"),
                last_message_timestamp:
                  action.payload.conversation.last_message_timestamp,
              }

              set({
                conversations: updateCollectionItemByID<IResolvedConversation>(
                  state.conversations,
                  updatedConversation.id,
                  updatedConversation
                ),
              })
            }
          }
          break
        }

        case ConversationTypes.SetConversationUnreadCountByID:
          if (action.payload.unreadMap) {
            const updatedConversations = state.conversations.map((conv) => {
              const unreadCount = action.payload.unreadMap.find(
                (x) => x.id === conv.id
              )
              return unreadCount
                ? { ...conv, unread_count: unreadCount.unread_count }
                : conv
            })

            set({ conversations: updatedConversations })
          }
          break

        case ConversationTypes.SetConversation:
          set({ currentConversationID: action.payload.conversation })
          break

        case ConversationTypes.SetGoToMessageID:
          set({ goToMessageID: action.payload.messageID })
          break

        case ConversationTypes.SetGroups:
          set({ groups: action.payload.groups })
          break

        case ConversationTypes.SetSelectedContacts:
          set({ selectedContacts: action.payload.contacts })
          break

        case ConversationTypes.ResetGroup:
          set({ editGroup: null })
          break

        case ConversationTypes.ResetContact:
          set({ editContact: null })
          break

        case ConversationTypes.ResetRoutes:
          set({ toggledRoutes: [] })
          break

        case ConversationTypes.SetMessage:
          set({ message: action.payload.message })
          break

        case ConversationTypes.ResetConversation:
          set({ currentConversationID: "" })
          break

        case ConversationTypes.SetLoading:
          set({ loading: action.payload.loading })
          break

        case ConversationTypes.SetSubscriptionsActive:
          set({ subscriptionActive: action.payload.active })
          break

        case ConversationTypes.Reset:
          set({ ...initialState })
          break

        default:
          break
      }
    },
  })
)

export default useConversationStore
