import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import cogoToast from "@clevertrackdk/cogo-toast"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Input,
  InputVariantEnum,
  Loading,
  ValidationInput,
} from "@clevertrack/shared"
import { IContact, PopOverRoutesEnum } from "../conversations.types"
import Panel from "app/Panel"
import { PanelHeader } from "app/Panel/Header"
import { FormContext } from "@clevertrack/shared/src/components/Form/context"
import { useFormData } from "hooks/useFormData"
import { ContactPrefix } from "."
import Icon from "lib/Icon"
import { useFirestoreContact } from "services/firestore/contact"
import { usePromise } from "hooks/usePromise"
import { ConversationActions, ConversationTypes } from "../actions"
import { useConversation } from "../hooks"
import { ContactItem } from "./Item"
import Accordion from "lib/Accordion"
import useConversationStore from "../store"

const InputWithPrefix = styled.div`
  display: grid;
  grid-template-columns: 2rem 4rem 1fr;
`

type ContactFormProps = {
  contact?: IContact
  onClose: (...args) => void
  onContactCreated: (...args) => void
  inline: boolean
}

const defaultContactDataModel: IContact = {
  email: "",
  first_name: "",
  last_name: "",
  area_code: 45,
  phone: null,
}

export const ContactForm: React.FC<ContactFormProps> = ({
  onClose,
  onContactCreated,
  inline = false,
  ...props
}) => {
  const {
    state: { fields },
  } = useContext(FormContext)
  const { toggledRoutes, editContact, dispatch } = useConversationStore(
    (state) => ({
      toggledRoutes: state.toggledRoutes,
      editContact: state.editContact,
      dispatch: state.dispatch,
    })
  )

  const [
    contactData,
    updateContactData,
    resetContactModel,
    setDataModel,
  ] = useFormData<IContact>(editContact ?? defaultContactDataModel)
  const { createCompanyContact, saveCompanyContact } = useFirestoreContact()
  const { setPromise, promiseStatus, loading } = usePromise()
  const { deleteContact, contactsSorted } = useConversation()
  const [existingContact, setExistingContact] = useState<IContact | null>(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  const onSuccess = (res) => {
    if (onContactCreated) onContactCreated({ id: res, ...contactData })
    cogoToast.success("Kontaktperson gemt")
    setExistingContact(null)
    resetContactModel()
    formRef.current.reset()
    onClose()
  }

  const onError = (error, msg?: string) => {
    const err = [msg ?? `Kunne ikke gemme kontaktperson:`, error].filter(
      Boolean
    )
    cogoToast.error(err.join(" "))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (contactData.id) {
      await setPromise({
        promise: saveCompanyContact(contactData),
        onSuccess,
        onError,
      })
    } else {
      await setPromise({
        promise: createCompanyContact(contactData),
        onSuccess,
        onError,
      })
    }
  }

  const onDelete = async (e) => {
    e.preventDefault()
    await setPromise({
      promise: deleteContact(contactData.id),
      onSuccess: () => onSuccess("Kontaktperson slettet"),
      onError: (err) => onError(err, "Kunne ikke slette kontaktperson"),
    })
    setExistingContact(null)
    resetContactModel()
    onClose()
  }

  const onCancel = () => {
    onClose()
  }

  const renderConfirm = () => {
    return (
      <Button
        variant="transparent"
        tw="p-2 px-4 m-0 font-normal text-brand-500"
        type="submit"
        disabled={!contactData.phone || fields.length > 0 || existingContact}
      >
        {contactData.id ? "Gem" : "Opret"}
      </Button>
    )
  }

  const renderCancel = () => {
    return (
      <Button
        variant="transparent"
        tw="p-2 px-4 m-0 font-normal text-brand-red-500"
        type="button"
        onClick={onCancel}
      >
        Annuller
      </Button>
    )
  }

  const renderDelete = () => {
    return (
      <Button
        type="button"
        size="sm"
        variant="danger"
        onClick={() => setConfirmDelete(true)}
      >
        Slet kontakt
      </Button>
    )
  }

  const onSelectExistingContact = () => {
    if (existingContact) {
      dispatch(
        ConversationActions(ConversationTypes.ToggleRoute, {
          key: PopOverRoutesEnum.CreateContact,
        })
      )
      dispatch(
        ConversationActions(ConversationTypes.EditContact, {
          contact: existingContact,
        })
      )
      formRef.current.reset()
    }
  }

  const checkExisitingContacts = () => {
    const result = contactsSorted
      .filter((contact) => !!contact.first_name)
      .find((contact) => contact.phone === contactData.phone)
    if (result) {
      setExistingContact(result)
    }
  }

  useEffect(() => {
    if (editContact) {
      setDataModel(editContact)
      setExistingContact(null)
    }
  }, [editContact])

  useEffect(() => {
    if (toggledRoutes.includes(PopOverRoutesEnum.CreateContact)) {
      if (formRef.current) {
        formRef.current.reset()
      }
      setExistingContact(null)
      resetContactModel()
      setDataModel(defaultContactDataModel)
      dispatch(ConversationActions(ConversationTypes.ResetContact, null))
    }
  }, [toggledRoutes])

  useEffect(() => {
    if (toggledRoutes.includes(PopOverRoutesEnum.CreateContact))
      // Check if the contact already exists
      checkExisitingContacts()
  }, [contactData])

  const contactPrefix = [
    contactData.first_name?.substring(0, 1),
    contactData.last_name?.substring(0, 1),
  ].join("")

  const renderPhone = () => {
    return (
      <FormField
        label={inline ? "Telefon" : "Telefon*"}
        validationKey="phone"
        size="sm"
      >
        <InputWithPrefix>
          <Input value="+" readOnly variant={InputVariantEnum.PREPEND} />
          <Input
            type="number"
            required
            readOnly
            defaultValue={contactData.area_code}
            variant={InputVariantEnum.PREPEND}
            onChange={(e) => updateContactData("area_code", e.target.value)}
          />
          <ValidationInput
            id="phone"
            placeholder="Indtast telefon"
            required={!inline}
            defaultValue={contactData.phone}
            onChange={(e) =>
              updateContactData("phone", e.target.value.replace(" ", ""))
            }
          />
        </InputWithPrefix>
      </FormField>
    )
  }

  const renderEmail = () => {
    return (
      <FormField
        label={inline ? "Email*" : "Email"}
        validationKey="email"
        size="sm"
      >
        <ValidationInput
          id="email"
          placeholder="Indtast email"
          type="email"
          required={inline}
          onChange={(e) => updateContactData("email", e.target.value)}
          defaultValue={contactData.email}
        />
      </FormField>
    )
  }

  return (
    <Panel
      css={
        inline ? [tw`p-0 mt-8 bg-white h-full`] : [tw`p-6 pt-8 bg-white h-full`]
      }
      {...props}
    >
      {inline && <h3 tw="text-2xl mb-4">Ny kontaktperson</h3>}
      <Form onSubmit={onSubmit} forwardRef={formRef}>
        {!inline && (
          <>
            <PanelHeader
              title={editContact ? "Redigér kontakt" : "Ny kontakt"}
              confirmComponent={renderConfirm()}
              cancelComponent={renderCancel()}
            />
            <div tw="py-8">
              <ContactPrefix tw="w-24 h-24 mx-auto">
                <span tw="text-4xl">
                  {contactPrefix !== "" && isNaN(+contactPrefix) ? (
                    contactPrefix
                  ) : (
                    <Icon icon="person" />
                  )}
                </span>
              </ContactPrefix>
            </div>
          </>
        )}
        {toggledRoutes.includes(PopOverRoutesEnum.CreateContact) && (
          <Accordion toggled={!!existingContact}>
            <div tw="mb-4 bg-yellow-100 p-4 border-yellow-300 border-solid border">
              <span tw="text-lg text-yellow-600">
                Kontakten findes allerede
              </span>
              {existingContact && (
                <ContactItem
                  tw="cursor-pointer"
                  contact={existingContact}
                  onSelect={onSelectExistingContact}
                />
              )}
            </div>
          </Accordion>
        )}
        {inline ? renderEmail() : renderPhone()}
        <FormField label="Fornavn*" validationKey="first_name" size="sm">
          <ValidationInput
            id="first_name"
            placeholder="Indtast fornavn"
            onChange={(e) => updateContactData("first_name", e.target.value)}
            defaultValue={contactData.first_name}
            required
          />
        </FormField>
        <FormField label="Efternavn" validationKey="last_name" size="sm">
          <ValidationInput
            id="last_name"
            placeholder="Indtast efternavn"
            onChange={(e) => updateContactData("last_name", e.target.value)}
            defaultValue={contactData.last_name}
          />
        </FormField>
        {inline ? renderPhone() : renderEmail()}

        {inline && (
          <ButtonGroup tw="mt-8 items-center flex" position="right">
            <Button
              variant="transparent"
              tw="m-0 font-normal text-brand-red-500 flex-grow-0 w-48"
              type="button"
              size="sm"
              onClick={onCancel}
            >
              Annuller
            </Button>
            <Button
              size="sm"
              variant="default"
              tw="m-0 font-normal flex-grow-0 w-48"
              type="submit"
              disabled={
                !inline
                  ? !contactData.phone || fields.length > 0 || existingContact
                  : !contactData.email || fields.length > 0 || existingContact
              }
            >
              {contactData.id ? "Gem" : "Opret"}
            </Button>
          </ButtonGroup>
        )}

        {editContact && (
          <ButtonGroup tw="mt-8" position="center" sticky>
            {confirmDelete ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  variant="transparent"
                  onClick={() => setConfirmDelete(false)}
                >
                  Annuller
                </Button>
                <Button
                  size="sm"
                  type="button"
                  variant="danger"
                  onClick={onDelete}
                >
                  Bekræft sletning?
                </Button>
              </>
            ) : (
              renderDelete()
            )}
          </ButtonGroup>
        )}
      </Form>
      {loading && <Loading loadingText="Gemmer" />}
    </Panel>
  )
}
