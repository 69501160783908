import Duration from "duration"
import format from "date-fns/format"
import { enGB, da } from "date-fns/locale"
import { match } from "./fpBooster"
import { ViewportEnum } from "hooks/useViewport"

/**
 * Takes a timestamp and converts to locale datetime string
 */

const locales = {
  en: enGB,
  da,
}

const formatStrDefault = {
  en: "dd.MM.yyyy p",
  da: "dd.MM.yyyy 'kl.' p",
}

function formatDate(timestamp, locale = "da", formatStr) {
  try {
    // const date = parse(timestamp, "dd-MM-yyyy HH:mm:ss", new Date())
    return format(timestamp, formatStr || formatStrDefault[locale], {
      locale: locales[locale],
    })
  } catch (error) {
    console.error(error)
  }
}

const durationUnits = {
  [ViewportEnum.Phone]: ["md.", "d.", "t.", "m.", "s."],
  [ViewportEnum.TabletPortrait]: ["md.", "d.", "t.", "m.", "s."],
  [ViewportEnum.TabletLandscape]: ["md.", "d.", "t.", "min.", "s."],
  [ViewportEnum.Desktop]: ["md.", "d.", "t.", "min.", "s."],
  [ViewportEnum.LargeDesktop]: ["md.", "d.", "t.", "min.", "s."],
}

export interface IFormatSecondsOptions {
  leadingZeros?: boolean
  viewport?: ViewportEnum
  showSeconds?: boolean
  shortUnits?: boolean
  debug?: boolean
  verboseDuration?: boolean
}

export const formatSecondsToDuration = (
  seconds: number,
  options?: IFormatSecondsOptions = {
    showSeconds: false,
  }
) => {
  const minutesRemainder = seconds - Math.floor(seconds / 60) * 60
  const roundedSeconds = minutesRemainder > 30 ? seconds + 60 : seconds

  const zeroPadding = (num) => String(num).padStart(2, "0")
  const applyLeadingZeros = (dur) => ({
    ...dur,
    hour: zeroPadding(+dur.hour),
    minute: zeroPadding(+dur.minute),
    second: zeroPadding(+dur.second),
  })

  const start = new Date(0)
  const stop = new Date(new Date(0).setTime(1000 * seconds))

  const duration = new Duration(start, stop)
  const durationObject = match(options)
    .on(
      (x) => !!x && x.leadingZeros,
      () => applyLeadingZeros(duration)
    )
    .otherwise(() => duration)

  let [months, days, hrs, min, sec] = options.viewport
    ? durationUnits[options.viewport]
    : ["md.", "d.", "t.", "min.", "s."]

  if (options.showSeconds || options.shortUnits) {
    ;[months, days, hrs, min, sec] = ["md.", "d.", "t.", "m.", "s."]
  }

  const renderMonths = () => {
    return durationObject.months >= 1
      ? `${durationObject.months} ${months}`
      : ``
  }

  const renderDays = () => {
    return durationObject.day >= 1 ? `${durationObject.day} ${days}` : ``
  }

  const renderHours = () => {
    return durationObject.hour >= 1 ? `${durationObject.hour} ${hrs}` : ``
  }

  const renderMinutes = () => {
    return durationObject.minute >= 1
      ? `${durationObject.minute} ${min}`
      : `${
          !options.showSeconds &&
          durationObject.minute < 1 &&
          durationObject.hour < 1
            ? `< 1 ${min}`
            : `${
                durationObject.minute < 1
                  ? `${durationObject.minute} ${min}`
                  : ``
              }`
        }`
  }

  const renderSeconds = () => {
    return options.showSeconds && durationObject.second > 0
      ? `${durationObject.second} ${sec}`
      : null
  }

  if (options.verboseDuration) {
    return {
      months: renderMonths(),
      days: renderDays(),
      hrs: renderHours(),
      min: renderMinutes(),
      sec: renderSeconds(),
    }
  }

  return [
    renderMonths(),
    renderDays(),
    renderHours(),
    renderMinutes(),
    renderSeconds(),
  ]
    .filter(Boolean)
    .join(" ")
}

// Legacy - cool code that Mads wrote, but not as efficient
export const formatDecimalTimeLEGACY = (input) => {
  const [hrs, minutes] = input.split(".")
  return formatSecondsToDuration(
    (Number(hrs) * 60 + (Number(minutes) / 10) * 60) * 60
  )
}

// more simple (reduced) calculation
export const formatDecimalTime = (input, showSeconds = true) => {
  return formatSecondsToDuration(+(input * 3600).toFixed(), {
    showSeconds,
  })
}

export { formatDate }
