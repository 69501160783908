import React, { useContext, useEffect, useMemo } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { ConversationList } from "./List"
import Panel from "app/Panel"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Button, ButtonGroup, Loading } from "@clevertrack/shared"
import { IConversationsSharedProps } from "app/Conversations"
import {
  PopOverRoutesEnum,
  RouteTypeEnum,
} from "app/Conversations/conversations.types"
import { useConversation } from "app/Conversations/hooks"
import { ConversationSearch } from "app/Conversations/Conversation/Search"
import { StyledGrid } from "../StyledGrid"
import Checkbox from "lib/Checkbox"
import { UserContext } from "app/User/context"
import { UserVisibilitySettings } from "app/User/types"
import { SearchActions, SearchTypes } from "app/Search/actions"
import { SearchContext } from "app/Search/context"
import { useUser } from "app/User/hooks"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import useConversationStore from "app/Conversations/store"

const StyledHeader = styled.header`
  ${tw`pt-4`}
`

interface IConversationsProps extends IConversationsSharedProps {
  children?: React.ReactNode
}

export const Conversations: React.FC<IConversationsProps> = ({
  onNavigate,
  onToggleScreen,
  ...props
}) => {
  const {
    state: { userSettings },
  } = useContext(UserContext)
  const { toggleUserVisibilitySetting } = useUser()
  const { toggledRoutes, loading, dispatch } = useConversationStore(
    (state) => ({
      toggledRoutes: state.toggledRoutes,
      loading: state.loading,
      dispatch: state.dispatch,
    })
  )

  const { dispatch: searchDispatch } = useContext(SearchContext)
  const { conversationsSorted } = useConversation()

  const onToggleNotifications = async () => {
    await toggleUserVisibilitySetting(
      UserVisibilitySettings.MessengerNotifications
    )
  }

  useEffect(() => {
    if (toggledRoutes.includes(RouteTypeEnum.Contacts)) {
      searchDispatch(SearchActions(SearchTypes.ResetSearch))
    }
  }, [])

  useEffect(() => {
    if (conversationsSorted.length > 0) {
      dispatch(
        ConversationActions(ConversationTypes.SetLoading, { loading: false })
      )
    }
  }, [conversationsSorted])

  return useMemo(
    () => (
      <StyledGrid>
        <StyledHeader>
          <ConversationSearch withPhoneBorder placeholder="Søg i samtaler" />
        </StyledHeader>
        {conversationsSorted.length > 0 ? (
          <>
            <ConversationList
              conversations={conversationsSorted}
              onSelect={() =>
                onToggleScreen(PopOverRoutesEnum.ViewConversation)
              }
            />
            <ButtonGroup
              tw="bg-white bottom-0 px-8 md:(bottom-0 pb-4)"
              sticky="sticky"
              position="bottom"
            >
              <Checkbox
                tw="justify-center md:(self-center) items-center flex text-brand-black-base"
                appearance="toggle"
                icon="notifications"
                iconUntoggled="notification_off"
                checked={userSettings?.visibilitySettings?.includes(
                  UserVisibilitySettings.MessengerNotifications
                )}
                onChange={onToggleNotifications}
              >
                <span tw="hidden md:(text-xl block)">
                  Notifikationer slået{" "}
                  {`${
                    userSettings?.visibilitySettings?.includes(
                      UserVisibilitySettings.MessengerNotifications
                    )
                      ? `til`
                      : `fra`
                  }`}
                </span>
              </Checkbox>
              <Button
                variant="primary"
                onClick={() => onToggleScreen(PopOverRoutesEnum.NewMessage)}
                icon="left"
              >
                <Icon icon="plus" size={IconSizeEnum.SM} />
                <span>Ny samtale</span>
              </Button>
            </ButtonGroup>
          </>
        ) : loading ? (
          <Panel tw="flex flex-col items-center pt-24">
            <span tw="w-24 h-24 bg-gray-200 flex text-gray-600 items-center justify-center rounded-full">
              <Icon icon="conversation" size={IconSizeEnum.LG} />
            </span>
            <Loading loadingText="Indlæser samtaler" icon="spinner" />
          </Panel>
        ) : (
          <Panel tw="flex flex-col items-center pt-24">
            <span tw="w-24 h-24 bg-gray-200 flex text-gray-600 items-center justify-center rounded-full">
              <Icon icon="conversation" size={IconSizeEnum.LG} />
            </span>
            <h3>Ingen samtaler</h3>
            <Button
              variant="primary"
              onClick={() => onToggleScreen(PopOverRoutesEnum.NewMessage)}
            >
              Start din første samtale
            </Button>
          </Panel>
        )}
      </StyledGrid>
    ),
    [conversationsSorted]
  )
}
