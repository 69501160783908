import React from "react"
import {
  IContactOrContactGroup,
  IContact,
} from "app/Conversations/conversations.types"
import {
  BaseContact,
  ContactPrefix,
  ContactHeader,
  ContactLatestMessage,
} from "app/Conversations/Contact"
import "twin.macro"
import Icon, { IconSizeEnum } from "lib/Icon"
import useConversationStore from "../store"

interface IContactItemProps {
  contact: IContactOrContactGroup
  onSelect: (c: IContactOrContactGroup | IContact) => void
  onDeselect?: (c: IContactOrContactGroup | IContact) => void
  children?: React.ReactNode
  allowSelectWhileDisabled?: boolean
}

export const ContactItem: React.FC<IContactItemProps> = ({
  contact,
  onSelect,
  onDeselect,
  children,
  allowSelectWhileDisabled,
  ...props
}) => {
  const { selectedContacts } = useConversationStore((state) => ({
    selectedContacts: state.selectedContacts,
  }))

  const isGroup = contact.hasOwnProperty("contact_ids")
  const isDisabled = selectedContacts.map((c) => c.id).includes(contact.id)
  let groupBody
  if (isGroup && contact.contact_ids.filter(Boolean).length > 0) {
    const count = contact.contact_ids.length
    const contactsInGroup = contact?.contact_ids
      .slice(0, 2)
      .map((x) =>
        x.first_name && x.last_name
          ? [x.first_name, `${x.last_name?.substring(0, 1)}.`].join(" ")
          : `+${x.area_code} ${x.phone}`
      )

    groupBody =
      count > 2
        ? `${contactsInGroup.join(", ")} og ${count - 2} mere`
        : `${contactsInGroup.join(" og ")}`
  }
  if (!contact) return null

  const contactPrefix = [
    contact.first_name?.substring(0, 1),
    contact.last_name?.substring(0, 1),
  ].join("")

  return (
    <BaseContact
      key={contact.id}
      prefix={
        <ContactPrefix>
          {isGroup ? (
            <Icon size={IconSizeEnum.MD} icon="groups" />
          ) : contactPrefix !== "" && isNaN(+contactPrefix) ? (
            contactPrefix
          ) : (
            <Icon icon="person" size={IconSizeEnum.SM} />
          )}
        </ContactPrefix>
      }
      onClick={() =>
        !isDisabled
          ? onSelect(contact)
          : allowSelectWhileDisabled
          ? onDeselect && onDeselect(contact)
          : {}
      }
      isDisabled={isDisabled}
      allowSelectWhileDisabled={allowSelectWhileDisabled}
      {...props}
    >
      {isGroup ? (
        <ContactHeader name={contact.title} />
      ) : (
        <ContactHeader
          name={[contact.first_name, contact.last_name].join(" ")}
          appendix={<span tw="text-xl text-gray-800">{contact.email}</span>}
        />
      )}
      {contact.phone && (
        <ContactLatestMessage
          body={
            contact.area_code
              ? `+${contact.area_code} ${contact.phone}`
              : `${contact.phone}`
          }
          read
        />
      )}
      {isGroup && <ContactLatestMessage body={groupBody} read />}
      {children}
    </BaseContact>
  )
}
