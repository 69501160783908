import React, { useContext } from "react"
import "twin.macro"
import {
  IMessage,
  IResolvedConversation,
  MessageStatusEnum,
} from "app/Conversations/conversations.types"
import {
  BaseContact,
  ContactPrefix,
  ContactHeader,
  ContactLatestMessage,
} from "app/Conversations/Contact"
import format from "date-fns/format"
import { da } from "date-fns/locale"
import isToday from "date-fns/isToday"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import Icon, { IconSizeEnum } from "lib/Icon"
import { SearchContext } from "app/Search/context"
import { UserContext } from "app/User/context"
import { UserVisibilitySettings } from "app/User/types"
import useConversationStore from "app/Conversations/store"

interface IConversationListProps {
  conversations: IResolvedConversation[]
  onSelect: () => void
}

const timeSinceSentErrorTreshold = 60 * 60 * 5 // 5 Minutes

export const ConversationList: React.FC<IConversationListProps> = (props) => {
  const { conversations, onSelect } = props
  const {
    state: { query, suggestions, results },
  } = useContext(SearchContext)
  const {
    state: { user, userSettings },
  } = useContext(UserContext)
  const { dispatch } = useConversationStore((state) => ({
    dispatch: state.dispatch,
  }))

  const onSelectConversation = (
    conversation: IResolvedConversation,
    messageID: number | null
  ) => {
    dispatch(
      ConversationActions(ConversationTypes.SetConversation, {
        conversation: conversation.id,
      })
    )
    dispatch(
      ConversationActions(ConversationTypes.SetSelectedContacts, {
        contacts: [conversation.contact],
      })
    )
    dispatch(
      ConversationActions(ConversationTypes.SetGoToMessageID, {
        messageID,
      })
    )
    onSelect()
  }

  const displayedConversationList: IResolvedConversation[] =
    query.length > 1
      ? [...results, ...suggestions].map(({ item }) => item)
      : conversations

  const mappedConversationList =
    query.length > 0
      ? displayedConversationList.map((x) => {
          if (
            x.messages.some(
              (msg) =>
                msg.message.toLowerCase().indexOf(query.toLowerCase()) > -1
            )
          ) {
            return {
              ...x,
              messages: x.messages.filter(
                (msg) =>
                  msg.message.toLowerCase().indexOf(query.toLowerCase()) > -1
              ),
            }
          } else {
            return x
          }
        })
      : displayedConversationList

  return (
    <ul tw="p-0 m-0">
      {mappedConversationList.map((conversation) => {
        if (!conversation?.messages || conversation.messages.length === 0)
          return null
        const latestMessage: IMessage =
          conversation.messages[conversation.messages.length - 1]
        const unreadCount = conversation.unread_count
        const latestMessageTime = format(
          new Date(latestMessage.time),
          "dd.MM.yyyy",
          { locale: da }
        )
        const latestMessageDeliveryError = [
          MessageStatusEnum.EXPIRED,
          MessageStatusEnum.DELETED,
          MessageStatusEnum.UNDELIVERABLE,
          MessageStatusEnum.SKIPPED,
          MessageStatusEnum.REJECTED,
        ].includes(latestMessage.status)
        const today = isToday(latestMessage.time)
        const contactPrefix = [
          conversation.contact?.first_name?.substr(0, 1),
          conversation.contact?.last_name?.substr(0, 1),
        ].join("")

        const body = latestMessage.message
        const latestMessageID =
          query !== "" &&
          latestMessage.message.toLowerCase().indexOf(query.toLowerCase()) > -1
            ? latestMessage.id
            : null

        return (
          <BaseContact
            key={conversation.id}
            prefix={
              <ContactPrefix>
                <span tw="relative">
                  {contactPrefix !== "" && isNaN(+contactPrefix) ? (
                    contactPrefix
                  ) : (
                    <Icon icon="person" size={IconSizeEnum.SM} />
                  )}
                  {unreadCount > 0 &&
                    userSettings?.visibilitySettings?.includes(
                      UserVisibilitySettings.MessengerNotifications
                    ) && (
                      <span tw="bg-brand-red-500 rounded-full text-base flex items-center justify-center w-8 h-8 font-bold text-white absolute -top-5 -left-5">
                        {unreadCount}
                      </span>
                    )}
                </span>
              </ContactPrefix>
            }
            onClick={() => onSelectConversation(conversation, latestMessageID)}
          >
            <ContactHeader
              name={
                <>
                  {contactPrefix !== ""
                    ? [
                        conversation.contact.first_name,
                        conversation.contact.last_name,
                      ].join(" ")
                    : [
                        `${
                          conversation.contact?.area_code
                            ? `+${conversation.contact?.area_code}`
                            : ``
                        }`,
                        conversation.contact.phone,
                      ].join(" ")}
                </>
              }
              appendix={today ? "I dag" : latestMessageTime}
            />
            <ContactLatestMessage
              highlight={query}
              body={body}
              read={
                latestMessage.message_read ||
                (conversation?.contact.hasOwnProperty("last_read_by") &&
                  conversation.last_message_timestamp <
                    conversation.contact.last_read_by[user.id])
              }
              latestMessageDeliveryError={latestMessageDeliveryError}
            />
          </BaseContact>
        )
      })}
    </ul>
  )
}
