/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, useContext, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import NavToggle from "lib/Navigation/Toggle"
import NavUntoggle from "lib/Navigation/Untoggle"
import { Link } from "gatsby"
import { Logo, Dropdown, Button, Responsive } from "@clevertrack/shared"
import NavLink from "components/Navigation/NavLink"

import { AppContext, NavigationContext, ViewportContext } from "context"
import { isViewport } from "helpers"
import tw from "twin.macro"

import Header from "./Header"
import Navigation from "./Navigation"
import NavItem from "./NavItem"
import OffCanvasNav from "app/OffCanvasNav"

import I18n from "app/I18n"
import User from "app/User"
import UserMenu from "app/User/Menu"
import TileContainer from "app/TileContainer"
import { ConversationWidget } from "app/Conversations"
import { NotificationCenterWidget } from "app/NotificationCenter"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { MenuTypeEnum } from "context/App/app.types"
import { TapBar } from "lib/Navigation/TapBar"
import { useNavigationItems } from "./useNavigation"
import { CompanyContext } from "app/Company/context"
import { COMPANYFUNCTIONS } from "data/featureFlags"
import { NotificationCenterContext } from "app/NotificationCenter/context"
import { Unsubscribe } from "firebase/firestore"
import { useRealtimePlatformHealth } from "services/realtime/platform"
import { NotificationType } from "app/NotificationCenter/notificationcenter.types"
import { DeviceInstallation } from "app/DeviceInstallation"

const StyledDashboard = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header"
                       "main";
  position: relative;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.white};
  padding-bottom: 6.2rem;

  ${(props) => (props.preventScroll ? `overflow: hidden; height: 100vh;` : ``)}

  h3::first-letter {
    text-transform: uppercase;
  }

  ${(props) => props.theme.media.tablet_portrait_up`

  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: 30rem 1fr;
    grid-template-rows: 8rem 1fr;
    grid-template-areas: "logo navigation"
                         "sidebar main";
    padding-bottom: 0;
    height: 100vh;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-height: 100vh;
    grid-template-columns: 32rem 1fr;
    grid-template-rows: 8rem 1fr;
  `}

  ${(props) => props.theme.media.big_desktop_up`
    max-height: 100vh;
    grid-template-columns: 32rem 1fr;
    grid-template-rows: 8rem 1fr;
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    max-height: 100vh;
    grid-template-columns: 35.5rem 1fr;
    grid-template-rows: 8rem 1fr;
  `}
`

const StyledNavToggle = styled(NavToggle)`
  border: none;
  margin: 0;
  position: absolute;
  height: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "all" : "none")};
  transition: ease-out 0.2s all;
  transform: ${(props) => (props.show ? "rotateZ(0)" : "rotateZ(-90deg)")};

  &:focus {
    outline: 0;
  }
`

const StyledNavUntoggle = styled(NavUntoggle)`
  border: none;
  margin: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;

  &:focus {
    outline: 0;
  }
`

const StyledNavigation = styled.nav`
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) =>
    props.hasTitle ? `none` : `0 3px 3px rgba(0, 0, 0, .2)`};
  display: grid;
  grid-template-columns: ${(props) => (props.hasSidebar ? `1fr` : `4.4rem`)};
  position: absolute;
  right: 1rem;
  left: 1rem;
  top: 2rem;
  z-index: 150;
  min-height: ${(props) => (props.hasSidebar ? `5.8rem` : `4.4rem`)};

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    &:only-child {
      width: auto;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    grid-area: navigation;
    position: static;
    grid-template-columns: 1fr auto;
    box-shadow: none;
    background: ${props.theme.colors.white};
    border-bottom: 1px solid ${props.theme.colors.grayLight};
  `}
`

const StyledMain = styled.div`
  height: 100%;
  transition: height ease-out .5s;
  position: relative;

  ${(props) =>
    props.sidebarToggled &&
    `
      height: 50vh;
  `}
  ${(props) => (props.hasSidebar ? `grid-row: 1 / span 2;` : ``)}
  ${(props) => (props.hasTitle ? `grid-row: 2 / span 1;` : ``)}
  ${(props) => (props.allowOverflowScroll ? `overflow-y: scroll;` : ``)}
  ${(props) => props.theme.media.tablet_landscape_up`

    ${
      props.hasSidebar
        ? `grid-area: main;`
        : `
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
      `
    }
    z-index: 50;
    min-height: 100%;
  `}
`

const StyledLogo = styled(Link)`
  ${tw`print:hidden`}
  ${(props) => props.theme.media.tablet_landscape_up`
    grid-area: logo;
    background: ${props.theme.colors.white};
    display: flex;
    align-items: center;
    padding: 2rem;
    border-right: 1px solid ${props.theme.colors.grayLight};
    border-bottom: 1px solid ${props.theme.colors.grayLight};
  `}
`

const StyledSidebar = styled(TileContainer)`
  ${tw`print:hidden`}
  ${(props) => props.theme.media.tablet_landscape_up`
    overflow-x: hidden;
    max-height: 100%;
    grid-area: sidebar;
    border-right: 1px solid ${props.theme.colors.grayLight};
  `}
`

const StyledPrimaryComponent = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    flex-direction: column;
  `}
`

const StyledAlert = styled.div`
  display: flex;
  align-items: center;
  z-index: 900;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  ${tw`bg-brand-red-500 text-white text-xl px-4 py-4`}

  p {
    ${tw`m-0 text-xl leading-snug`}
  }

  button {
    ${tw`ml-auto`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    align-items: center;
    top: 0;
    bottom: auto;
  `}
`

const StyledUser = styled.div`
  position: relative;

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-left: auto;
    display: flex;
  `}
`

const StyledBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1200;
  pointer-events: ${(props) => (props.show ? "all" : "none")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity ease-out 0.3s;
`

const Dashboard = ({
  children,
  user,
  sidebar,
  hideSidebar,
  title,
  allowOverflowScroll,
  showPlatformStatus,
}) => {
  const {
    state: { toggledMenus, navToggled, sidebarToggled, settingsToggled },
    dispatch,
  } = useContext(AppContext)
  const viewport = useContext(ViewportContext)
  const {
    state: {
      company: { functions },
    },
  } = useContext(CompanyContext)
  const { tapBarNavigationItems } = useNavigationItems()
  const {
    state: { platformUpdates },
  } = useContext(NotificationCenterContext)

  const systemMessage = useMemo(
    () =>
      platformUpdates.filter(
        (x) => x.category === NotificationType.SYSTEM_MESSAGE && x.isCurrent
      )[0],
    [platformUpdates]
  )

  const otherMessages = useMemo(
    () =>
      platformUpdates.filter(
        (x) => x.category !== NotificationType.SYSTEM_MESSAGE && x.isCurrent
      )[0],
    [platformUpdates]
  )

  function handleNavToggle(toggled) {
    dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, { toggled }))
  }

  function handleSidebarToggle(toggled) {
    dispatch(GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled }))
  }

  function handleSettingsToggle({ e, toggled }) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    dispatch(GlobalAppActions(GlobalAppTypes.ToggleSettings, { toggled }))
  }

  const onNavToggle = () =>
    dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, {}))

  const subscriptionRef = useRef<Unsubscribe | null>(null)

  const { subscribeToPlatformUpdates } = useRealtimePlatformHealth()

  useEffect(() => {
    if (subscriptionRef.current === null) {
      subscriptionRef.current = subscribeToPlatformUpdates()
    }
    return () => {
      if (subscriptionRef.current) subscriptionRef.current()
    }
  }, [])

  return (
    <>
      <StyledDashboard
        preventScroll={
          navToggled ||
          toggledMenus.includes(MenuTypeEnum.Conversations) ||
          toggledMenus.includes(MenuTypeEnum.Notifications)
        }
      >
        {!isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
          <>
            <StyledLogo to="/app">
              <Logo colored />
            </StyledLogo>
            <StyledNavigation>
              <Navigation user={user} />
              <StyledUser>
                <Navigation tw="m-0 lg:(mr-4)">
                  <NavItem>
                    <NotificationCenterWidget />
                  </NavItem>
                  {Object.values(functions).includes(
                    COMPANYFUNCTIONS.MESSENGER
                  ) && (
                    <NavItem>
                      <ConversationWidget />
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      to="/app/settings"
                      onClick={(e) =>
                        handleSettingsToggle({ e, toggled: !settingsToggled })
                      }
                      activeClassName="active"
                      className={settingsToggled ? "active" : ""}
                      partiallyActive={true}
                    >
                      <Icon icon="cog" />
                      <span>
                        <I18n strKey="nav_settings" />
                      </span>
                    </NavLink>
                  </NavItem>
                </Navigation>
                <Dropdown toggled={settingsToggled}>
                  <UserMenu
                    user={user}
                    onSelectNavItem={() =>
                      handleSettingsToggle({ toggled: false })
                    }
                  />
                </Dropdown>
              </StyledUser>
            </StyledNavigation>
          </>
        ) : (
          <Header hasSidebar={!!sidebar} hasTitle={!!title}>
            {title && <h2>{title}</h2>}
          </Header>
        )}

        {!hideSidebar &&
          !isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) &&
          sidebar && (
            <StyledSidebar>
              <StyledPrimaryComponent>{sidebar}</StyledPrimaryComponent>
            </StyledSidebar>
          )}
        {sidebar && !hideSidebar ? (
          <StyledMain
            hasSidebar
            sidebarToggled={sidebarToggled}
            allowOverflowScroll={allowOverflowScroll}
            id="mainContainer"
            css={
              showPlatformStatus
                ? (otherMessages && !systemMessage) ||
                  (!otherMessages && systemMessage)
                  ? [tw`pt-12`]
                  : otherMessages && systemMessage
                  ? [tw`pt-24`]
                  : ``
                : ``
            }
          >
            {(otherMessages || systemMessage) && showPlatformStatus && (
              <NotificationCenterWidget showPhoneStatus={true} />
            )}
            {isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) && (
              <>
                <StyledNavigation
                  hasSidebar={true}
                  css={
                    showPlatformStatus
                      ? (otherMessages && !systemMessage) ||
                        (!otherMessages && systemMessage)
                        ? [tw`mt-14 md:(mt-0)`]
                        : otherMessages && systemMessage
                        ? [tw`mt-28 md:(mt-0)`]
                        : ``
                      : ``
                  }
                >
                  <StyledPrimaryComponent>{sidebar}</StyledPrimaryComponent>
                </StyledNavigation>
              </>
            )}
            {children}
          </StyledMain>
        ) : (
          <StyledMain
            hasSidebar={
              sidebar && hideSidebar && !!isViewport(viewport, hideSidebar)
            }
            allowOverflowScroll={allowOverflowScroll}
            sidebarToggled={sidebarToggled}
            id="mainContainer"
          >
            {children}
          </StyledMain>
        )}
        <DeviceInstallation />
      </StyledDashboard>

      {isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) && (
        <>
          <OffCanvasNav
            navToggle={
              <StyledNavUntoggle onToggle={() => handleNavToggle(false)} />
            }
            nav={
              <Navigation
                user={user}
                onSelectNavItem={() => handleNavToggle(false)}
              />
            }
          >
            <StyledUser>
              <User tw="pl-4" />
              <UserMenu
                onSelectNavItem={() => handleNavToggle(false)}
                onSignOut={() => handleSidebarToggle(false)}
                user={user}
              />
            </StyledUser>
          </OffCanvasNav>
          <StyledBackdrop
            onClick={() => handleNavToggle(false)}
            show={navToggled}
          />
        </>
      )}
      <Responsive
        phone={
          <TapBar
            key="tap-nav"
            items={tapBarNavigationItems}
            onNavToggle={onNavToggle}
          />
        }
        tabletLandscape={false}
        desktop={false}
        largeDesktop={false}
      />
    </>
  )
}

Dashboard.defaultProps = {
  sidebar: null,
}

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node,
}

export default Dashboard
